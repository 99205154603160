import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import Select from "react-select"

const ViewGroupTP = props => {
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("group/get_by_id_tp", { cyc_niv_sem_spec_id:id }).then(res => {
      setSelectNiveau(res.data.Group.niveau)
      setSelectSemestre(res.data.Group.semestre)
      setSelectSpecialite(res.data.Group.specialite)
      setArrayGroupe(res.data.Group.group_cours)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Semestre:
                          </Label>
                          <Select
                            value={selectSemestre}
                            isSearchable={true}
                            isMulti={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Niveau</Label>
                          <Select value={selectNiveau} isSearchable={true} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité
                          </Label>
                          <Select
                            value={selectSpecialite}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <CardTitle className="h4 mt-4">Groupe de cours</CardTitle>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Identifiant groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nom groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Type groupe
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Capacité
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nombre etudiant
                        </th>
                      </tr>
                    </thead>
                    {arrayGroupe.map((el, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="col-md-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Identifiant Groupe"
                              value={el.identifiantGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Nom Groupe"
                              value={el.NomGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Type Groupe"
                              value={el.TypeGroupe}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Capacité"
                              value={el.capacite}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                              type="number"
                              className="form-control"
                              placeholder="Nombre Etudiant"
                              value={el.nombreEtudiant}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Col lg="6">
                      <div className="text-center mt-4">
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Groupe")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewGroupTP
ViewGroupTP.propTypes = {
  history: PropTypes.object,
}
