import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  CardTitle,
  Form,
  Label,
  Container,
  CardBody,
  Card,
  ModalHeader,
  ModalBody,
  Button, Modal
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import DatePicker from "react-datepicker";
import Select from "react-select";
import {Link} from "react-router-dom";

const DataTableEmpoiCourSoir = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const [annee, setAnnee] = useState("");
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    setAnnee(annee_id)
    const res = await API.post("seance/soire/list_soire", {
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.SeanceSoire)
      setLoading(true)
    })
  }, [props.semestre])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const toggleDelete = async () => {
    const res = await API.post("seance/soire/delete", {
      id: id,
    }).then(res => {
      const resD = API.post("seance/soire/list_soire",{annee_id:annee_id}).then(resD => {
        setOrders(resD.data.SeanceSoire)
      })
      setLoading(true)
    })
  }
  useEffect(() => {
    setModal(false)
  }, [orders])
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "enseignant",
      text: "Enseignant",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: "Groupe",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: "Semestre",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "salle",
      text: "Salle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Modification",
      isDummyField: true,
      text: "Modification",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/EditEmploiCourSoir?id=" + row.id}>
              <i
                  style={{ color: "cornflowerblue", cursor: "pointer" }}
                  className="fas fa-edit"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "Suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
                style={{ color: "#ED6464", cursor: "pointer" }}
                onClick={() => toggle(row.id)}
                className="fas fa-trash-alt"
            ></i>
          </div>

    },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        {loading ? (
                <div className="page-content">
                  <Container fluid>
                    <div>
                      <Card>
                        <CardBody>
                          <CardTitle style={{ color: "#556ee6" }} className="h4">
                            Liste des emplois de formation cours de soir
                          </CardTitle>
                          <Row className="mt-4">
                            <Col xs="12">
                              <ToolkitProvider
                                  keyField="id"
                                  data={orders}
                                  columns={Columns()}
                                  search
                                  bootstrap4
                              >
                                {toolkitProps => (
                                    <div>
                                      <div>


                                        <div className="mb-12 row" style={{ display: "flex" }}>
                                          <div className="col-md-6 mb-3 row">
                                            <div className="col-md-12 search-box-etd">
                                              <div
                                                  className="search-box me-2 mb-2 d-inline-block"
                                                  style={{ width: "100%" }}
                                              >
                                                <div className="position-relative">
                                                  <SearchBar {...toolkitProps.searchProps} />
                                                  <i className="bx bx-search-alt search-icon" />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <BootstrapTable
                                          wrapperClasses="table-responsive"
                                          noDataIndication={() => <NoDataIndication />}
                                          striped={false}
                                          bordered={false}
                                          classes={"table align-middle table-nowrap"}
                                          headerWrapperClasses={"table-light"}
                                          hover
                                          pagination={paginationFactory(pageOptions)}
                                          {...toolkitProps.baseProps}
                                      />
                                    </div>
                                )}
                              </ToolkitProvider>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                    </div>
                  </Container>
                </div>
        ) : (
          <div>
            <div className="page-content">
              <Container fluid>
                <div>
                  <Card>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              En cours ...
            </h4>
                  </Card>
          </div>
              </Container>
            </div>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggle}
            tag="h4"
        >
          {"Suppression emploi cour de soir"}
        </ModalHeader>
        <ModalBody>
          <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {"Êtes-Vous sûr de vouloir supprimer cette emploi ?"}

              </p>
            </div>
            <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
            >
              <div>
                <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleDelete}
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                  ></i>
                  {"Oui"}
                </Button>
              </div>
              <div>
                <Button
                    onClick={toggle}
                    color="danger"
                    className="btn-rounded "
                >
                  <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                  ></i>
                  {"Non"}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableEmpoiCourSoir)
DataTableEmpoiCourSoir.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
  section:PropTypes.any
}
