import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { Grid } from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import axios from "axios"
import { useForm } from "react-hook-form"
import API from "../../api";

const Login = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})
  const [anneeScolaire, setAnneeScolaire] = useState([])
  const [api_password, setApi_paasword] = useState("3KbmGc5tZhuDbPtb")
  const [selectAnneeScolaire, setSelectAnneeScolaire] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    const res = await API.get("annee/select").then(res => {
      setAnneeScolaire(res.data.Annee[0].ListAnnee)
      setSelectAnneeScolaire(res.data.Annee[0].CurrentAnnee[0])
    })
  }, [])

  const onSubmit = async data => {
    setLoading(false)
    var anneeScolaire = selectAnneeScolaire.value
    const res = await API.post(
      "login",
      {
        password: data.password,
        email: data.email,
        API_PASSWORD: api_password,
        annee_id:anneeScolaire,
      }
    )
    if (res.data.status === 200) {
      localStorage.setItem("I18N_LANGUAGE", "fr")
      localStorage.setItem("userAuthScolarite", JSON.stringify(res.data))
      props.history.push("/dashboard")
      localStorage.setItem("anneeScolaire", JSON.stringify(anneeScolaire))
      window.location.reload(false)
    } else {
      setLoading(true)
      toast.error("⛔ Veuillez vérifier votre CIN et mot de passe ou l'année scolaire!", {
        containerId: "A",
      })
    }
  }

  return (
    <div>
      {loading ? (
        <div
          style={{
            backgroundImage: `url(${cresusFond})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <div style={{ paddingTop: "7%" }}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#556ee6",
                              textAlign: "center",
                            }}
                          >
                            Espace Scolarité
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <div className="p-2">
                        <form
                          className="form-horizontal"
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          <div className="mb-3">
                            <input
                              name="email"
                              placeholder="Entrer email"
                              type="text"
                              className="form-control"
                              {...register("email", { required: true })}
                            />
                            {errors.email && (
                              <span style={{ color: "red" }}>
                                required email
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <input
                              name="password"
                              placeholder="Entrer password"
                              type="password"
                              className="form-control"
                              {...register("password", { required: true })}
                            />
                            {errors.password && (
                              <span style={{ color: "red" }}>
                                required password
                              </span>
                            )}
                          </div>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Année universitaire
                            </Label>
                            <Select
                              options={anneeScolaire}
                              isSearchable={true}
                              value={selectAnneeScolaire}
                              onChange={e => setSelectAnneeScolaire(e)}
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1"></Label>
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              CONNECTER
                            </button>
                          </div>
                        </form>
                      </div>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with
                        <i className="mdi mdi-heart text-danger" /> by
                        <a href="https://cresus.pro/"> cresus.pro</a>
                      </p>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
            <ToastContainer
              transition={Flip}
              enableMultiContainer
              containerId={"A"}
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2500}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-50px",
            marginLeft: "-50px",
          }}
        >
          <Grid heigth="100" width="100" color="#86B5E9" ariaLabel="loading" />
        </div>
      )}
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
