import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import {selectOptions} from "@testing-library/user-event/dist/select-options";
import * as stream from "stream";

const EditEnseignant = props => {
  const [id, setId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")

  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [gender, setGender] = useState("")
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState("");
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("getById_enseignant", { id }).then(res => {
      setNom(res.data.Enseignant.firstName)
      setPrenom(res.data.Enseignant.lastName)
      setGender(res.data.Enseignant.gender)
      setNumero(res.data.Enseignant.code)
      setEmail(res.data.Enseignant.email)
      setTelephone(res.data.Enseignant.telephone)
      setActive(res.data.Enseignant.active)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (nom == "" && prenom == "" && telephone == "") {
      toast.error("⛔ Remplir les champs obligatoires", {
        containerId: "A",
      })
    } else if (numero != "") {
      const res = await API.post("enseignant/update", {
        id: id,
        active: active,
        firstName: nom,
        lastName: prenom,
        gender: gender,
        code: numero,
        email: email,
        telephone: telephone
      }).then(res => {
        props.history.push("/Enseignant")
      })
    } else {
      toast.error("⛔  CIN obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <AvForm>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Nom :</Label>
                          <AvField
                            name="nom"
                            placeholder="Entrer nom"
                            type="text"
                            errorMessage="* nom obligatoire"
                            className="form-control"
                            value={nom}
                            validate={{ required: { value: true } }}
                            onChange={e => setNom(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Prénom :
                          </Label>
                          <AvField
                            name="prenom"
                            placeholder="Entrer prénom"
                            type="text"
                            errorMessage="* prenom obligatoire"
                            className="form-control"
                            value={prenom}
                            validate={{ required: { value: true } }}
                            onChange={e => setPrenom(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <div className="mb-3">
                          <Label className="d-block mb-3">Sexe :</Label>
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="form-check-input"
                              value={gender}
                              checked={gender == 1}
                              onClick={() => setGender(1)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline1"
                            >
                              Masculin
                            </Label>
                          </div>
                          &nbsp;
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                              checked={gender == 2}
                              onClick={() => setGender(2)}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                            >
                              Feminin
                            </Label>
                          </div>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Identifiant :{" "}
                          </Label>
                          <AvField
                              name="numero"
                              placeholder="Entrer identifiant"
                              type="text"
                              errorMessage="Min 8 caractère"
                              className="form-control"
                              value={numero}
                              validate={{
                                required: { value: true },
                                min: { value: 8 },
                              }}
                              onChange={e => setNumero(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <AvField
                            name="email"
                            label="E-Mail"
                            placeholder="Entrer un email valide"
                            type="email"
                            errorMessage="Email invalide"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            validate={{
                              required: { value: true },
                              email: { value: true },
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Téléphone :
                          </Label>
                          <AvField
                            name="tel"
                            placeholder="Entrer téléphone"
                            type="text"
                            errorMessage="* Téléphone obligatoire"
                            className="form-control"
                            value={telephone}
                            validate={{
                              required: { value: true },
                              min: { value: 8 },
                            }}
                            onChange={e => setTelephone(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>

                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Enseignant")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="submit"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </AvForm>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(EditEnseignant)
EditEnseignant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
