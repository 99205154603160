import React, { useEffect, useState } from "react"
import PropTypes, { array } from "prop-types"
import API from "../../api"
import APIE from "../../apiens"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Switch from "react-switch"
import FileDownload from "js-file-download";
//
const DataTableEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "code",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "name",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "telephone",
      text: "Téléphone",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Groupes",
      isDummyField: true,
      text: "Groupes",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/EnseignantsGroupes?id=" + row.id}>
              <i
                  style={{ color: "darkmagenta", cursor: "pointer" }}
                  className="fas fa-users-cog"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "Detail Groupes",
      isDummyField: true,
      text: "Detail Groupes",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/EnseignantsGroupesDetail?id=" + row.id}>
              <i
                  style={{ color: "mediumpurple", cursor: "pointer" }}
                  className="fas fa-user-check"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "Paiement",
      isDummyField: true,
      text: "Paiement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/PaiementEnseignant?id=" + row.id}>
              <i
                  style={{ color: "red", cursor: "pointer"}}
                  className="fas fa-users-cog"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "DetailPaiement",
      isDummyField: true,
      text: "Détail Paiement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/DetailPaiementEnseignant?id=" + row.id}>
              <i
                  style={{  color: "green", cursor: "pointer"}}
                  className="fas fa-user-cog"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: "Exploration",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={"/DetailEnseignants?id=" + row.id}>
              <i
                  style={{ color: "darkcyan", cursor: "pointer" }}
                  className="fas fa-eye"
              ></i>
            </Link>
          </div>
      ),
    },
    {
      dataField: "suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
          <div>
            {row.count == 0 ? (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <i
                      style={{color: "#ED6464", cursor: "pointer"}}
                      onClick={() => toggle(row.id)}
                      className="fas fa-trash-alt"
                  ></i>
                </div>
            ) : (
                <div style={{display: "flex", justifyContent: "center"}}>
                  <i
                      style={{color: "#A7A3A3", cursor: "pointer"}}
                      className="fas fa-trash-alt"
                      title="Cet enseignant déja utilisé"
                  ></i>
                </div>
            )}
          </div>
      ),

    },
    {
      dataField: "active",
      isDummyField: true,
      text: "Etat",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
          <Switch
              uncheckedIcon={<Offsymbol />}
              checkedIcon={<OnSymbol />}
              className="me-1 mb-sm-8 mb-2"
              onColor="#34c38f"
              onChange={() => activeEnseignant(row)}
              checked={row.active}
          />
      ),
    },
  ]
  const toggle = id => {
    setModal(!modal)
    setId(id)
  }
  const Offsymbol = () => {
    return (
        <div
            title="Activé"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2,
            }}
        >
          {" "}
          A
        </div>
    )
  }

  const OnSymbol =  props => {
    return (
        <div
            title="Désactivé"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2,
            }}
        >
          {" "}
          D
        </div>
    )
  }

  const { SearchBar } = Search

  useEffect(async () => {
      API.get("enseignant/liste").then(res => {
        setOrders(res.data.enseignants)
        setLoading(true)
      })
  }, [])
  const toggleDelete = async () => {
    API.post("enseignant/delete", {
      id: id,
    }).then(res => {
      setModal(false)
      API.get("enseignant/liste").then(res => {
        setOrders(res.data.enseignants)
        setLoading(true)
      })
    })
  }

  const activeEnseignant = async row => {
    var active = row.active
    var etudiantId = row.id
    if (active == 0) {
      var active = 1
      const res = await API.post("enseignant/activer", {
        id: etudiantId,
        active: active,
      }).then(res => {
        API.get("enseignant/liste").then(resEnse => {
          toast.success(" 🔓✔️ Enseignant Activé Successfully", {
            containerId: "A",
          })
          setOrders(resEnse.data.enseignants)
        })
      })

    } else if (active == 1) {
      var active = 0
      const res = await API.post("enseignant/activer", {
        id: etudiantId,
        active: active,
      }).then(res => {
      API.get("enseignant/liste").then(resEnse => {
        toast.success(" 🔒❌ Enseignant Désactivé Successfully ", {
          containerId: "A",
        })
        setOrders(resEnse.data.enseignants)
      })
      })

    }
  }
  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row className="mt-4">
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <div>
                            <div className="mb-3 row" style={{ display: "flex" }}>
                              <div className="col-md-6 mb-3 row">
                                <div className="col-md-8 search-box-etd">
                                  <div
                                      className="search-box me-2 mb-2 d-inline-block"
                                      style={{ width: "100%" }}
                                  >
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 appliquer-button">
                                <a
                                    href="https://backscolarite.esps.com.tn/api/impression_list_enseignants"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                  <button className="btn btn-primary btn btn-primary">
                                    <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                    Liste Enseignant
                                  </button>
                                </a>
                              </div>
                            </div>
                          </div>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...
                </h4>
              </div>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader
              style={{width: "100% !important", textAlign: "center !important"}}
              toggle={toggle}
              tag="h4"
          >
            {" Suppression etudiant"}{" "}
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{display: "flex", justifyContent: "center"}}>
                <p>Voulez-vous supprimer cet etudiant ?</p>
              </div>
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      color="info"
                      className="btn-rounded "
                      onClick={toggleDelete}
                  >
                    <i
                        style={{color: "white"}}
                        className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggle}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{color: "white"}}
                        className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(DataTableEnseignant)
DataTableEnseignant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
