import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//i18n
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditMatiere = props => {
  const [matiere, setMatiere] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [nbrTp, setNbrTp] = useState("")

  const [loading, setLoading] = useState(false)
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("matiere/get_by_id", { id }).then(res => {
      setMatiere(res.data.Matiere.name)
      setNbrCours(res.data.Matiere.nb_cour)
      setNbrTp(res.data.Matiere.nb_tp)
      setSelectSemestre(res.data.Matiere.semestre_id)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (matiere != "" || selectSemestre!="") {
      const res = await API.post("matiere/update", {
        id: id,
        name: matiere,
        nbhCours: nbrCours,
        nbhTP:nbrTp,
        semestre_id:selectSemestre.value,
      }).then(res => {
        props.history.push("/Matiere")
      })
    } else {
      toast.error("⛔ Nom matière obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Semestre</Label>
                          <Select
                              options={semestre}
                              isSearchable={true}
                              value={selectSemestre}
                              onChange={e => setSelectSemestre(e)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Matière :
                          </Label>
                          <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder="Matiere"
                              value={matiere}
                              onChange={e => setMatiere(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure cours :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Nbr heure cours"
                            value={nbrCours}
                            onChange={e => setNbrCours(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre heure tp :
                          </Label>
                          <Input
                            lg="3"
                            type="number"
                            className="form-control"
                            placeholder="Nbr heure tp"
                            value={nbrTp}
                            onChange={e => setNbrTp(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/Matiere")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={edit}
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMatiere)
EditMatiere.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
