import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, CardTitle, Form, Label} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import DatePicker from "react-datepicker";
import Select from "react-select";

const DataTableEns = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const [annee, setAnnee] = useState("");
  useEffect(async () => {

    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setAnnee(annee_id)
    const res = await API.post("emploi/enseignant/list", {
      semestre_id: props.semestre,
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.enseignants)
      setLoading(true)
    })
  }, [props.semestre])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "name",
      text: "Enseignant",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Consultation",
      isDummyField: true,
      text: "Consultation",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {props.semestre == 5 ? (
              <i
                  style={{
                    color: row.emploi == 0 ? "cornflowerblue" : "green",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                      props.history.push(
                          "/EmploiEnseignant?id=" + row.id + "/" + row.semestre
                      )
                  }
                  className="fas fa-eye"
              ></i>
          ):(
              <i
                  style={{
                    color: row.emploi == 0 ? "cornflowerblue" : "green",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                      props.history.push(
                          "/EmploiEnseignant?id=" + row.id + "/" + row.semestre
                      )
                  }
                  className="fas fa-eye"
              ></i>
          )}

        </div>
      ),
    },
    {
      dataField: "Emploi",
      isDummyField: true,
      text: "Emploi",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          { props.section == 3 ? (
              <i
                  style={{
                    color: row.emploi == 0 ? "cornflowerblue" : "green",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                      props.history.push(
                          "/AddScheduleEnseignantSoir?id=" + row.id + "/" + row.semestre+"/"+props.section
                      )
                  }
                  className="fas fa-calendar-alt"
              ></i>
          ):(
              <i
                  style={{
                    color: row.emploi == 0 ? "cornflowerblue" : "green",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                      props.history.push(
                          "/AddScheduleEnseignant?id=" + row.id + "/" + row.semestre
                      )
                  }
                  className="fas fa-calendar-alt"
              ></i>
          )}

        </div>
      ),
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <a
          href={
            "https://backscolarite.esps.com.tn/api/impression_enseignant_shedules/" +
            row.id +
            "/" +
            row.semestre+"/"+annee
          }
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            className="fas fa-file-download"
          ></i>
        </a>
      ),
    },
  ]

  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <div>
                      <div className="mb-12 row" style={{ display: "flex" }}>
                        <div className="col-md-6 mb-3 row">
                          <div className="col-md-12 search-box-etd">
                            <div
                              className="search-box me-2 mb-2 d-inline-block"
                              style={{ width: "100%" }}
                            >
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            // display: "flex",
                            // justifyContent: "flex-end",
                          }}
                          className="col-md-6 mb-3 row"
                        >
                          <div
                            style={{ textAlign: "right" ,display:'contents'}}
                            className="col-md-6 select-action"
                          >


                            <a style={{margin:"0px 10px"}}
                              href={
                                "https://backscolarite.esps.com.tn/api/impression_enseignants_shedules" + "/" +
                                props.semestre+"/"+annee
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="btn btn-primary btn btn-primary">
                                <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                Emplois enseignants
                              </button>
                            </a>


                          </div>
                        </div>
                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              En cours ...
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableEns)
DataTableEns.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
  section:PropTypes.any
}
