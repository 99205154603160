import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Col,
  Label,
  Row,
  Spinner,
  Container,
  Card,
  CardBody, Form, Button,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const AddScheduleEnseignantSoir = props => {
  const [loading, setLoading] = useState(true)
  const [enseignantId, setEnseignantId] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [date, setDate] = useState("")
  const [semestre_id, setSemestre_id] = useState("")
  const [typeFormation, setTypeFormation] = useState("");
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id_semestre = array[1]
    var array1 = id_semestre.split("/")
    var id = array1[0]
    var semestre_id = array1[1]
    var type_formation=array1[2]
    setEnseignantId(id)
    setSemestre_id(semestre_id)
    setTypeFormation(type_formation)
    API.post("matiere/get_by_enseignant_id", {
      enseignant_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setMatiere(resE.data.Matiere)
    })
    API.get("salle/select").then(res => {
      setSalle(res.data.Salle)
    })
  }, [])
  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setSelectMatiere(event)
    setSelectSalle("")
    setSelectGroupe("")
    const resG = await API.post("emploi/get_group_cour_jour", {
      semestre_id: semestre_id,
      matiere_id: event.value,
      enseignant_id: enseignantId,
      annee_id:annee_id,
    }).then(resG => {
      setGroupe(resG.data.Group)
    })
  }
  const getSalle = async event => {
    let dateComm = event
    let month = "" + (dateComm.getMonth() + 1)
    let day = "" + dateComm.getDate()
    let year = dateComm.getFullYear()
    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day
    var convertDate = [year, month, day].join("-")
    setDate(dateComm)
    setSelectSalle("")
    API.post("salle/select_cour_soire", {
     date:convertDate,
    }).then(resG => {
      setSalle(resG.data.Salle)
    })
  }
  const save = async () => {
    if (selectMatiere == "") {
      toast.error("⛔ Matière est obligatoire", {
        containerId: "A",
      })
    } else if(selectGroupe == ""){
      toast.error("⛔ Groupe est obligatoire", {
        containerId: "A",
      })
    }else if(selectSalle == ""){
      toast.error("⛔ Salle est obligatoire", {
        containerId: "A",
      })
    }else if(date == ""){
      toast.error("⛔ Date est obligatoire", {
        containerId: "A",
      })
    } else {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
      var type=0
      if(typeFormation == 2){
        var type=1
      }else if(typeFormation == 3){
        var type=2
      }
      API.post("seance/soire/add", {
        annee_id: annee_id,
        date:convertDate,
        matiere_id:selectMatiere.value,
        semestre_id:semestre_id,
        salle_id:selectSalle.value,
        group_id:selectGroupe.value,
        enseignant_id:enseignantId,
        type_formation_id:type
      }).then(resG => {
        props.history.push("/SchedulesEnseignants")
      })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                  <div>
                    <Form>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Matiere</Label>
                            <Select
                                options={matiere}
                                isSearchable={true}
                                value={selectMatiere}
                                onChange={e => getGroupe(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                             Date
                            </Label>
                            <DatePicker
                                className="form-control ddate"
                                selected={date}
                                onChange={e => getSalle(e)}
                                dateFormat="dd/MM/yyyy"
                                placeholder="YYYY-MM-DD"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Groupe</Label>
                            <Select
                                options={groupe}
                                isSearchable={true}
                                value={selectGroupe}
                                onChange={e => setSelectGroupe(e)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">Salle</Label>
                            <Select
                                options={salle}
                                isSearchable={true}
                                value={selectSalle}
                                onChange={e => setSelectSalle(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className="text-center mt-4"
                        >
                          <Button
                              type="button"
                              color="warning"
                              className="btn btn-warning  mb-2 me-2"
                              onClick={() =>props.history.push("/SchedulesEnseignants")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            className="text-center mt-4"
                        >
                          <button type="button" className="btn btn-primary " onClick={save}>
                            CONFIRMER
                          </button>
                        </div>
                      </Col>
                    </div>
                  </div>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>


    </React.Fragment>
  )
}

export default AddScheduleEnseignantSoir
AddScheduleEnseignantSoir.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
}
