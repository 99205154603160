import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalBody,
    Label,
    Input,
    Button,
    Form,
    ModalHeader,
} from "reactstrap"
import {withRouter} from "react-router-dom"
import DataTable from "react-data-table-component"
import {withTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import Switch from "react-switch"
import APIE from "../../apie"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

const DataTableEtudiantPaiement = props => {
    const [loading, setLoading] = useState(false)

    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    const [selectAnnee, setSelectAnnee] = useState("");
    const [orders, setOrders] = useState([])
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var user_id = userAuthScolarite.user.id
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        sizePerPage: 30,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "name_etudiant",
            text: "Nom et prénom",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "type_payment",
            text: "Type Paiement",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "prix",
            text: "Montant",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "date",
            text: "Date de paiement",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "numero_cheque",
            text: "Numéro du chèque",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "date_cheque",
            text: "Date du chèque",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "name_banque",
            text: "Nom du banque",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "responsable_cheque",
            text: "Responsable du chèque",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Reçu paiement",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    href={"https://backscolarite.esps.com.tn/api/etudiant/recuPayment/" +row.id }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#955cd5", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: "Modification",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={"/EditPaiementEtudiant?id=" + row.id}>
                        <i
                            style={{ color: "cornflowerblue", cursor: "pointer" }}
                            className="fas fa-edit"
                        ></i>
                    </Link>
                </div>
            ),
        },
        {
            dataField: "suppression",
            isDummyField: true,
            text: "Suppression",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                <div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <i
                            style={{color: "#ED6464", cursor: "pointer"}}
                            onClick={() => toggle(row.id)}
                            className="fas fa-trash-alt"
                        ></i>
                    </div>

                </div>
            ),

        }

    ]

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    const toggleDelete = async () => {
        const res = await API.post("ligne_payment/delete", {
            id: id,
            user_id:user_id
        }).then(res => {
            setModal(false)
            API.get("ligne_payment/list").then(res => {
                setOrders(res.data.ligne_payment)
                setLoading(true)
            })
        })
    }

    useEffect(async () => {
        setSelectAnnee(userAuthScolarite.user.annee_id)
        API.get("ligne_payment/list").then(res => {
            setOrders(res.data.ligne_payment)
            setLoading(true)
        })
    }, [])

    const { SearchBar } = Search
    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row className="mt-4">
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <div>
                                            <div className="mb-3 row" style={{ display: "flex" }}>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4"
                >
                    {" Suppression etudiant"}{" "}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>Voulez-vous supprimer cet etudiant ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DataTableEtudiantPaiement))
DataTableEtudiantPaiement.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
}
