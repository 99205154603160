import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import warning from "../../assets/images/téléchargement.png"
import $ from "jquery"

const ListEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [modal, setModal] = useState(false)
  const [etat, setEtat] = useState("")

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "name",
      text: "Nom & Prénom",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Compostage",
      isDummyField: true,
      text: "Compostage",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div>
          <label>{row.compostage}</label>
          <input
            type="number"
            id={row.etudiant_id}
            onChange={e => changeCompostage(e.target.value, row)}
            onBlur={e => checkCompostage(e.target.value, row)}
            defaultValue={row.compostage1}
            style={{ textAlign: "center", marginLeft: "10px" }}
          />
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await API.post("examen/final/list_etudiant", {
      examen_id: props.state_element.id,
      salle: props.listSalle.salle,
    }).then(res => {
      setList(res.data.ListEtudiant)
      setLoading(true)
    })
  }, [props.listSalle.salle])

  const changeCompostage = (event, element) => {
    let compostage = event
    setList(
      list.map((el, id) =>
        el.etudiant_id === element.etudiant_id
          ? Object.assign(el, { compostage1: compostage })
          : el
      )
    )
  }

  const checkCompostage = (event, element) => {
    const array = []
    for (let i = 0; i < list.length; i++) {
      array.push(list[i].compostage1)
    }
    const set = new Set(array)
    const duplicates = array.filter(item => {
      if (set.has(item)) {
        set.delete(item)
      } else {
        return item
      }
    })
    for (let i = 0; i < duplicates.length; i++) {
      if (duplicates[i].length == 3 && duplicates[i] != "000") {
        // $("#" + element.etudiant_id).css("border", "red")
        $("#" + element.etudiant_id).addClass("border_erreur")
        setModal(true)
        setEtat(1)
      }
    }
    if (duplicates.length == 0) {
      $("#" + element.etudiant_id).removeClass("border_erreur")
    }
    if (event.trim().length != 3) {
      setModal(true)
      $("#" + element.etudiant_id).css("border", "2px solid red")
      setEtat(2)
    } else {
      $("#" + element.etudiant_id).css("border-color", "black")
    }
  }

  const save = async () => {
    var etat = true
    for (let i = 0; i < list.length; i++) {
      if (list[i].compostage1.trim().length != 3) {
        var etat = false
      }
    }
    if (etat) {
      const res = await API.post("examen/final/add_compostage", {
        list: list,
        examen_id: props.state_element.id,
      }).then(res => {
        if (res.data.status == 500) {
          toast.error(res.data.msg, {
            containerId: "A",
          })
        } else {
          toast.success("Compostage ont ajouté aves succées ", {
            containerId: "A",
          })
        }
      })
    } else {
      toast.error(
        "⛔ Il y a un ou des champs vides ou compostage supérieure à 3 chiffre !",
        {
          containerId: "A",
        }
      )
    }
  }

  const close = () => {
    setModal(false)
  }

  return (
    <React.Fragment>
      {loading ? (
        <Row className="mt-4">
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={list}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <a
                          href={
                            "https://backscolarite.esps.com.tn/api/impression_list_vierge_emargement/" +
                            props.element.id +
                            "/" +
                            props.listSalle.salle
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button
                            type="button"
                            color="success"
                            className="btn  mb-2 me-2"
                          >
                            <i className="fas fa-file-export me-1" />
                            Export
                          </Button>
                        </a>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
                >
                  <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={() =>
                      props.history.push({
                        pathname: "/ListMatieres",
                        state: {
                          element: props.element,
                          niveau_id: props.niveau_id,
                        },
                      })
                    }
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
                >
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={save}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner type="grow" className="ms-6" color="primary" />
          </div>
          <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
            Chargement ...
          </h4>
        </div>
      )}
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} centered={true}>
        <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
          Faite Attention
        </ModalHeader>
        {etat == 1 ? (
          <ModalBody>
            <div className="d-flex justify-content-center">
              <img
                src={warning}
                alt=""
                style={{ width: 100, textAlign: "center" }}
              />
            </div>
            <p className="mt-4" style={{ textAlign: "center" }}>
              <strong> Compostage doit être unique </strong>
            </p>
          </ModalBody>
        ) : (
          <ModalBody>
            <div className="d-flex justify-content-center">
              <img
                src={warning}
                alt=""
                style={{ width: 100, textAlign: "center" }}
              />
            </div>
            <p className="mt-4" style={{ textAlign: "center" }}>
              <strong> Compostage doit être 3 chiffre </strong>
            </p>
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(ListEtudiant)
ListEtudiant.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  listSalle: PropTypes.object,
  element: PropTypes.object,
  niveau_id: PropTypes.object,
  state_element: PropTypes.object,
  t: PropTypes.any,
}
