import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import { Button, CardTitle, Col, Form, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

const AffectationEtudiant = props => {
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const [etudiant, setEtudiant] = useState([]);
  const [selectEtudiant, setSelectEtudiant] = useState("");
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [disbutReg, setDisbutReg] = useState(true)
  useEffect(async () => {
    API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
   API.post("etudiant/select",{annee_id}).then(resC => {
      setEtudiant(resC.data.Etudiant)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    const res = await API.post("niveau/get_by_cycle_unique",{cycle_id:cycle_id}).then(res => {
      setNiveau(res.data.Niveau)
    })

  }

  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }

  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,
      annee_id:annee_id
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }


  const save = async () => {
    if (selectCycle == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez choisir un cycle", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez choisir un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez choisir un groupe", {
        containerId: "A",
      })

    }  else if (selectEtudiant != "" ) {
      setDisbutReg(false)
      const res = await API.post("etudiant/add_group", {
        group_id:selectGroupe.value,
        etudiant_id:selectEtudiant.value,
       }).then(res => {
         if(res.data.errNum == 500){
           setDisbutReg(true)
           toast.error("⛔  Cet étudiant est déjà affecté à ce groupe !", {
             containerId: "A",
           })
         }else{
           setDisbutReg(false)
           props.setSection(1)
         }
      })
    } else {
      toast.error("⛔  Etudiant obligatoire !", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <AvForm>
          <Form>

            <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
              Diplôme :
            </CardTitle>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Cycle : </Label>
                  <Select
                    options={cycle}
                    isSearchable={true}
                    value={selectCycle}
                    onChange={e => getNiveau(e)}
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Niveau : </Label>
                  <Select
                    options={niveau}
                    isSearchable={true}
                    value={selectNiveau}
                    onChange={e => getSpec(e)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Spécialité :</Label>
                  <Select
                    options={specialite}
                    isSearchable={true}
                    value={selectSpecialite}
                    onChange={e => getGroupe(e)}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Groupe : </Label>
                  <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => setSelectGroupe(e)}
                  />
                </div>
              </Col>
              <Col lg="3">
                <div className="mb-3">
                  <Label for="basicpill-firstname-input1">Etudiant : </Label>
                  <Select
                    options={etudiant}
                    isSearchable={true}
                    value={selectEtudiant}
                    onChange={e => setSelectEtudiant(e)}
                  />
                </div>
              </Col>
            </Row>


          </Form>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  onClick={save}
                  type="submit"
                  className="btn btn-primary "
                  disabled={!disbutReg}
                >
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </AvForm>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default AffectationEtudiant
AffectationEtudiant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
