import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {
  Container,
  Card,
  CardBody,
  Row,
  CardTitle,
  Col,
  Label,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input
} from "reactstrap"
import API from "../../api";
import Select from "react-select";
import {Slide, toast, ToastContainer} from "react-toastify";

const EnseignantsGroupes = props => {
  const [loading, setLoading] = useState(true)
  const [arrayMatiere, setArrayMatiere] = useState([
    {
      group_id: "",
      matiere_id: "",
      prix_heure:""
    },
  ])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
    { value: 5, label: "Formation" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("");
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")

  const [groupe, setGroupe] = useState([])
  const [matiere, setMatiere] = useState([]);
  const [indexLigne, setIndexLigne] = useState("")

  const [modal, setModal] = useState(false)
  useEffect(async () => {

  }, [])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id

  const addLigne = () => {
    let element = {
      group_id: "",
      matiere_id: "",
      prix_heure:""
    }
    setArrayMatiere([...arrayMatiere, element])
  }
  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setArrayMatiere([]);
    const res = await API.post("niveau/select_by_semestre",{semestre_id:selectSemestre.value}).then(res => {
      setNiveau(res.data.Niveau)
    })
  }

  const addGroup = (event, index) => {
    let group_id =  event.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { group_id: group_id }) : el
        )
    )
  }
  const addMatiere = (event, index) => {
    let matiere_id = event.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { matiere_id: matiere_id }) : el
        )
    )
  }
  const addPrix = (event, index) => {
    let prix_heure = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { prix_heure: prix_heure }) : el
        )
    )
  }
  const getCycle = async event => {
    var semestre_id = event.value
    setSelectSemestre(event)
    setSelectCycle("")
    setSelectNiveau("")
    setSelectSpecialite("")
    setArrayMatiere([]);
    const resC = await API.post("cycle/getByNiveauxId",{semestre_id:semestre_id}).then(resC => {
      setCycle(resC.data.Cycle)
    })
   }
  const getSpec = async event => {
    var niveau_id = event.value
    setSelectNiveau(event)
    setSelectSpecialite("")
    setArrayMatiere([]);
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }
  const getGroupe = async event => {
    var specialite_id = event.value
    setSelectSpecialite(event)
    setArrayMatiere([]);
    API.post("group/get_by_group_niv_sem_spec_anne",{
      cycle_id:selectCycle.value,
      niveau_id:selectNiveau.value,
      semestre_id:selectSemestre.value,
      specialite_id:specialite_id,
      annee_id:annee_id
    }).then(resC => {
      setGroupe(resC.data.groups)
    })
    API.post("matiere/get_by_niv_sem_spec_anne",{
      cycle_id:selectCycle.value,
      niveau_id:selectNiveau.value,
      semestre_id:selectSemestre.value,
      specialite_id:specialite_id,
      annee_id:annee_id
    }).then(resC => {
      setMatiere(resC.data.groups)
    })
  }
  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }
  const toggleDelete = async () => {
    arrayMatiere.splice(indexLigne, 1)
    setModal(false)
  }
  const save = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]

    var etat = true
    for (let i = 0; i < arrayMatiere.length; i++) {
      var group_id = arrayMatiere[i].group_id
      var matiere_id = arrayMatiere[i].matiere_id
     if( selectSemestre == ""){
        var etat = false
        toast.error("⛔ Semestre Obligatoire ! ", {
          containerId: "A",
        })
      }else if( selectCycle == ""){
       var etat = false
       toast.error("⛔ Cycle Obligatoire ! ", {
         containerId: "A",
       })
     }else if( selectNiveau == ""){
       var etat = false
       toast.error("⛔ Niveau Obligatoire ! ", {
         containerId: "A",
       })
     }else if( selectSpecialite == ""){
       var etat = false
       toast.error("⛔ Spécialité Obligatoire ! ", {
         containerId: "A",
       })
     }else if ( group_id == ""){
        var etat = false
        toast.error("⛔ Choisir un groupe !", {
          containerId: "A",
        })
      }else if(matiere_id == ""){
        var etat = false
        toast.error("⛔ Choisir une matière ! ", {
          containerId: "A",
        })
      }
    }

    if (arrayMatiere.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins un groupe et une matière et le prix de l'heure", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("group/enseignant/matiere/add", {
        ligne: arrayMatiere,
        semestre_id:selectSemestre.value,
        annee_id:annee_id,
        enseignant_id:id,
        specialite_id:selectSpecialite.value
      }).then(res => {
        if(res.data.errNum == 422){
          toast.error("⛔ Il exist un group assosier avec la matiere ! ", {
            containerId: "A",
          })
        }else{
          props.history.push("/Enseignant")
        }
      })
    }
  }
  return (
      <React.Fragment>
        {loading ? (
            <div className="page-content">
              <Container fluid>
                <Card>
                  <CardBody>
            <Row>
              <div>
                <CardTitle style={{ color: "#556ee6" }} className="h4">
                  Critéres de Recherches
                </CardTitle>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Semestre : </Label>
                      <Select
                          options={semestre}
                          isSearchable={true}
                          value={selectSemestre}
                          onChange={e => getCycle(e)}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Cycle : </Label>
                      <Select
                          options={cycle}
                          isSearchable={true}
                          value={selectCycle}
                          onChange={e => getNiveau(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>

                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Niveau : </Label>
                      <Select
                          options={niveau}
                          isSearchable={true}
                          value={selectNiveau}
                          onChange={e => getSpec(e)}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Label for="basicpill-firstname-input1">Spécialité :</Label>
                      <Select
                          options={specialite}
                          isSearchable={true}
                          value={selectSpecialite}
                          onChange={e => getGroupe(e)}
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <table className="table table-bordered mt-4">
                    <thead>
                    <tr>
                      <th style={{ textAlign: "center" }} scope="col">
                        Groupe
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Matière
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Prix Heure
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        <i
                            style={{
                              color: "#a0ceb8",
                              cursor: "pointer",
                            }}
                            className="fas fa-plus"
                            onClick={addLigne}
                        />
                      </th>
                    </tr>
                    </thead>
                    {arrayMatiere.map((el, index) => (
                        <tbody key={index}>
                        <tr>
                          <td className="col-md-2">
                            <Select
                                options={groupe}
                                isSearchable={true}
                                // value={selectGroupe}
                                onChange={e => addGroup(e, index)}
                            />
                          </td>
                          <td className="col-md-2">
                            <Select
                                options={matiere}
                                isSearchable={true}
                                // value={selectMatiere}
                                onChange={e => addMatiere(e, index)}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                style={{ textAlign: "center" }}
                                type="text"
                                className="form-control"
                                placeholder="Prix Heure ..."
                                onChange={e => addPrix(e, index)}
                            />
                          </td>
                          <td className="col-md-1" style={{ textAlign: "center" }}>
                            <i
                                style={{ color: "#cc0000" }}
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                                onClick={() => toggle(index)}
                            />
                          </td>
                        </tr>
                        </tbody>
                    ))}
                  </table>
                </Row>
                <Row>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                      >
                        <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() =>props.history.push("/Enseignant")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                      >
                        <button type="button" className="btn btn-primary " onClick={save}>
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              </div>
            </Row>
                    <ToastContainer
                        transition={Slide}
                        enableMultiContainer
                        containerId={"A"}
                        position={toast.POSITION.TOP_RIGHT}
                        autoClose={2500}
                    />
                  </CardBody>
                </Card>
              </Container>
            </div>
        ) : (
            <div>
              <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
              >
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4 style={{ textAlign: "center", marginTop: "2%" }} className="ms-6">
                {" "}
                En cours ...
              </h4>
            </div>
        )}
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle} tag="h4">
            {" Suppression d'une ligne"}{" "}
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Voulez-vous supprimer cette ligne ?</p>
              </div>
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      onClick={toggleDelete}
                      color="info"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggle}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}
export default EnseignantsGroupes
EnseignantsGroupes.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
