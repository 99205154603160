import React, {useState} from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {Container, Card, CardBody, Nav, NavItem, NavLink} from "reactstrap"
import classnames from "classnames"
import {withRouter} from "react-router"
import DataTableCycle from "./DataTableCycle"
import AddCycle from "./AddCycle"
//i18n
import {withTranslation} from "react-i18next"
import AddCycleNiveau from "./AddCycleNiveau";
import DataTableCycleNiveau from "./DataTableCycleNiveau";

const Cycle = props => {
    const [section, setSection] = useState(1)

    const back = () => {
        setSection(1)
    }

    const renderBlock = section => {
        if (section === 1) {
            return <DataTableCycle/>
        } else if (section === 2) {
            return <AddCycle setSection={setSection} back={back}/>
        } else if (section === 3) {
            return <DataTableCycleNiveau setSection={setSection} back={back}/>
        } else if (section === 4) {
            return <AddCycleNiveau setSection={setSection} back={back}/>
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{props.t("Cycle")} | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                    {props.t("Cycle")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 2,
                                    })}
                                    onClick={() => {
                                        setSection(2)
                                    }}
                                >
                                    {props.t("Ajouter")} {props.t("Cycle")}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 3,
                                    })}
                                    onClick={() => {
                                        setSection(3)
                                    }}
                                >
                                    {props.t(" Cycle / Niveau")}
                                </NavLink>
                            </NavItem>
                          <NavItem>
                                <NavLink
                                    style={{cursor: "pointer"}}
                                    className={classnames({
                                        active: section === 4,
                                    })}
                                    onClick={() => {
                                        setSection(4)
                                    }}
                                >
                                    {props.t("Ajouter Cycle / Niveau")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Card>
                            <CardBody>{renderBlock(section)}</CardBody>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(Cycle))
Cycle.propTypes = {
    t: PropTypes.any,
}
