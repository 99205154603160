import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import countryList from "react-select-country-list"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const EditEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [sexe, setSexe] = useState(1);
  const [date, setDate] = useState("")
  const options = useMemo(() => countryList().getData(), [])

  const [email, setEmail] = useState("")

  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [cin, setCin] = useState("");
  const [prenom, setPrenom] = useState("")
  const [nom, setNom] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [tel, setTel] = useState("")
  const [delivreLe, setDelivreLe] = useState("");
  const [homologue, setHomologue] = useState("Homologué");
  useEffect(async () => {

    API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      setId(res.data.etudiant.id)
      setNom(res.data.etudiant.nom)
      setPrenom(res.data.etudiant.prenom)
      setCin(res.data.etudiant.cin)
      setDate(new Date(res.data.etudiant.dateNaissance))
      setDelivreLe(new Date(res.data.etudiant.delivre_le))
      setSexe(res.data.etudiant.sexe_id)
      setSelectNationality(res.data.etudiant.nationalite)
      setTel(res.data.etudiant.telephone)
      setEmail(res.data.etudiant.email)
      setSelectCycle(res.data.etudiant.cycle)
      setSelectNiveau(res.data.etudiant.niveau)
      setSelectSpecialite(res.data.etudiant.specialite)
      setSelectGroupe(res.data.etudiant.group)
      setHomologue(res.data.etudiant.Nature)
      setLoading(true)
    })
  }, [])

  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    const res = await API.post("niveau/get_by_cycle_unique",{cycle_id:cycle_id}).then(res => {
      setNiveau(res.data.Niveau)
    })
  }

  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }

  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,annee_id
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }

  const editEtudiant = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (date != null) {
      let dateComm = date
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    if (delivreLe != "") {
      let dateCommDelivreLe = delivreLe
      let monthDelivreLe = "" + (dateCommDelivreLe.getMonth() + 1)
      let dayDelivreLe = "" + dateCommDelivreLe.getDate()
      let yearDelivreLe = dateCommDelivreLe.getFullYear()
      if (monthDelivreLe.length < 2) monthDelivreLe = "0" + monthDelivreLe
      if (dayDelivreLe.length < 2) dayDelivreLe = "0" + dayDelivreLe
      var convertDateDelivreLe = [yearDelivreLe, monthDelivreLe, dayDelivreLe].join("-")
    } else {
      var convertDateDelivreLe = ""
    }
    if (selectCycle == "") {
      toast.error("⛔ Veuillez choisir un cycle", {
        containerId: "A",
      })
    } else if (selectSpecialite == "") {
      toast.error("⛔ Veuillez choisir une spécialité", {
        containerId: "A",
      })
    } else if (selectNiveau == "") {
      toast.error("⛔ Veuillez choisir un niveau", {
        containerId: "A",
      })
    } else if (selectGroupe == "") {
      toast.error("⛔ Veuillez choisir un groupe", {
        containerId: "A",
      })

    } else if (nom != "" && prenom != "" && cin != "" ) {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      API.post("etudiant/update", {
        id: id,
        nom:nom,
        prenom:prenom,
        cin:cin,
        sexe:sexe,
        dateNaissance:convertDate,
        nationalite_fr:selectNationality.label,
        email:email,
        telephone:tel,
        cycle_id:selectCycle.value,
        niveau_id:selectNiveau.value,
        specialite_id:selectSpecialite.value,
        group_id:selectGroupe,
        annee_id:annee_id,
        delivre_le:convertDateDelivreLe,
        Nature:homologue
      }).then(res => {
          props.history.push("/Etudiant")
        })
    } else {
      toast.error("⛔  Remplir les champs obligatoires", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                            Informations générales :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom :</Label>
                                <AvField
                                    value={nom}
                                    name="nom"
                                    placeholder="Entrer nom"
                                    type="text"
                                    errorMessage="* nom obligatoire"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={e => setNom(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col md="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Prénom :</Label>
                                <AvField
                                    value={prenom}
                                    name="prenom"
                                    placeholder="Entrer prénom"
                                    type="text"
                                    errorMessage="* prenom obligatoire"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    onChange={e => setPrenom(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">CIN :</Label>
                                <AvField
                                    value={cin}
                                    name="numero"
                                    placeholder="Entrer numéro"
                                    type="text"
                                    errorMessage="Min 8 caractère"
                                    className="form-control"
                                    validate={{ required: { value: true }, min: { value: 8 } }}
                                    onChange={e => setCin(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Délivré le :</Label>
                                <DatePicker
                                    className="form-control ddate"
                                    selected={delivreLe}
                                    onChange={e => setDelivreLe(e)}
                                    dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <AvField
                                    value={email}
                                    name="email"
                                    label="E-Mail"
                                    placeholder="Entrer un email valide"
                                    type="email"
                                    onChange={e => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="3">
                              <div className="mb-3">
                                <Label className="d-block mb-3">Sexe :</Label>
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline1"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={sexe == 1}
                                      onChange={e => setSexe(1)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline1"
                                  >
                                    Masculin
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline2"
                                      name="customRadioInline1"
                                      className="form-check-input"
                                      checked={sexe == 2}
                                      onChange={e => setSexe(2)}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline2"
                                  >
                                    Féminin
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label className="d-block mb-3">Nature :</Label>
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline3"
                                      name="customRadioInline3"
                                      className="form-check-input"
                                      checked={homologue == "Homologué"}
                                      onChange={e => setHomologue("Homologué")}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline3"
                                  >
                                    Homologué
                                  </Label>
                                </div>
                                &nbsp;
                                <div className="form-check form-check-inline">
                                  <Input
                                      type="radio"
                                      id="customRadioInline4"
                                      name="customRadioInline3"
                                      className="form-check-input"
                                      checked={homologue == "Non Homologué"}
                                      onChange={e => setHomologue("Non Homologué")}
                                  />
                                  <Label
                                      className="form-check-label"
                                      htmlFor="customRadioInline4"
                                  >
                                    Non Homologué
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Naissance :{" "}
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={date}
                                      onChange={e => setDate(e)}
                                      // dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nationalité :</Label>
                                <Select
                                    value={selectNationality}
                                    options={options}
                                    isSearchable={true}
                                    onChange={e => setSelectNationality(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Téléphone : </Label>
                                <AvField
                                    value={tel}
                                    name="tel"
                                    placeholder="Entrer téléphone"
                                    type="text"
                                    className="form-control"
                                    validate={{  min: { value: 8 } }}
                                    // validate={{ required: { value: true }, min: { value: 8 } }}
                                    onChange={e => setTel(e.target.value)}
                                />
                              </div>
                            </Col>

                          </Row>


                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                            Diplôme :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle : </Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    value={selectCycle}
                                    onChange={e => getNiveau(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau : </Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    value={selectNiveau}
                                    onChange={e => getSpec(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecialite}
                                    onChange={e => getGroupe(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Groupe : </Label>
                                <Select
                                    options={groupe}
                                    isSearchable={true}
                                    isMulti={true}
                                    value={selectGroupe}
                                    onChange={e => setSelectGroupe(e)}
                                />
                              </div>
                            </Col>
                          </Row>

                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>props.history.push("/Etudiant")}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  onClick={editEtudiant}
                                  type="submit"
                                  className="btn btn-primary "
                              >
                                CONFIRMER
                              </button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditEtudiant
EditEtudiant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
