import React, {useEffect, useMemo, useState} from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
  Spinner, Form, CardTitle, Label, Input,
} from "reactstrap"
import API from "../../api"
import countryList from "react-select-country-list";
import user1 from "../../assets/images/users/userImage.png"
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import Select from "react-select";
const DetailEtudiant = props => {
  const [id, setId] = useState("")
  const [sexe, setSexe] = useState(1);
  const [date, setDate] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const [email, setEmail] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [selectGroupe, setSelectGroupe] = useState("")
  const [cin, setCin] = useState("");
  const [prenom, setPrenom] = useState("")
  const [nom, setNom] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [tel, setTel] = useState("")

  const [loading, setLoading] = useState(false)
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [specialite, setSpecialite] = useState([])
  const [groupe, setGroupe] = useState([])
  const [homologue, setHomologue] = useState("Homologué");
  useEffect(async () => {

    API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      setId(res.data.etudiant.id)
      setNom(res.data.etudiant.nom)
      setPrenom(res.data.etudiant.prenom)
      setCin(res.data.etudiant.cin)
      setDate(new Date(res.data.etudiant.dateNaissance))
      setSexe(res.data.etudiant.sexe_id)
      setSelectNationality(res.data.etudiant.nationalite)
      setTel(res.data.etudiant.telephone)
      setEmail(res.data.etudiant.email)
      setSelectCycle(res.data.etudiant.cycle)
      setSelectNiveau(res.data.etudiant.niveau)
      setSelectSpecialite(res.data.etudiant.specialite)
      setSelectGroupe(res.data.etudiant.group)
      setHomologue(res.data.etudiant.Nature)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consultation Etudiant | Cresus </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
        {loading ? (

          <Container fluid={true}>
            <Row>
              <AvForm>
                <Form>
                  <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                    Informations générales :
                  </CardTitle>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Nom :</Label>
                        <AvField
                            readOnly={true}
                            value={nom}
                            name="nom"
                            placeholder="Entrer nom"
                            type="text"
                            errorMessage="* nom obligatoire"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={e => setNom(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Prénom :</Label>
                        <AvField
                            readOnly={true}
                            value={prenom}
                            name="prenom"
                            placeholder="Entrer prénom"
                            type="text"
                            errorMessage="* prenom obligatoire"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            onChange={e => setPrenom(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">CIN :</Label>
                        <AvField
                            readOnly={true}
                            value={cin}
                            name="numero"
                            placeholder="Entrer numéro"
                            type="text"
                            errorMessage="Min 8 caractère"
                            className="form-control"
                            validate={{ required: { value: true }, min: { value: 8 } }}
                            onChange={e => setCin(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <AvField
                            readOnly={true}
                            value={email}
                            name="email"
                            label="E-Mail"
                            placeholder="Entrer un email valide"
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label className="d-block mb-3">Sexe :</Label>
                        <div className="form-check form-check-inline">
                          <Input
                              disabled={true}
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="form-check-input"
                              checked={sexe == 1}
                              onChange={e => setSexe(1)}
                          />
                          <Label
                              className="form-check-label"
                              htmlFor="customRadioInline1"
                          >
                            Masculin
                          </Label>
                        </div>
                        &nbsp;
                        <div className="form-check form-check-inline">
                          <Input
                              disabled={true}
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                              checked={sexe == 2}
                              onChange={e => setSexe(2)}
                          />
                          <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                          >
                            Féminin
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label className="d-block mb-3">Nature :</Label>
                        <div className="form-check form-check-inline">
                          <Input
                              disabled={true}
                              type="radio"
                              id="customRadioInline3"
                              name="customRadioInline3"
                              className="form-check-input"
                              checked={homologue == "Homologué"}
                              onChange={e => setHomologue("Homologué")}
                          />
                          <Label
                              className="form-check-label"
                              htmlFor="customRadioInline3"
                          >
                            Homologué
                          </Label>
                        </div>
                        &nbsp;
                        <div className="form-check form-check-inline">
                          <Input
                              disabled={true}
                              type="radio"
                              id="customRadioInline4"
                              name="customRadioInline3"
                              className="form-check-input"
                              checked={homologue == "Non Homologué"}
                              onChange={e => setHomologue("Non Homologué")}
                          />
                          <Label
                              className="form-check-label"
                              htmlFor="customRadioInline4"
                          >
                            Non Homologué
                          </Label>
                        </div>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Date de Naissance :{" "}
                        </Label>
                        <Col md={12} className="pr-0">
                          <DatePicker
                              readOnly={true}
                              className="form-control ddate"
                              selected={date}
                              onChange={e => setDate(e)}
                              // dateFormat="dd/MM/yyyy"
                          />
                        </Col>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Nationalité :</Label>
                        <Select
                            isDisabled={true}
                            value={selectNationality}
                            options={options}
                            isSearchable={true}
                            onChange={e => setSelectNationality(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Téléphone : </Label>
                        <AvField
                            readOnly={true}
                            value={tel}
                            name="tel"
                            placeholder="Entrer téléphone"
                            type="text"
                            className="form-control"
                            validate={{  min: { value: 8 } }}
                            // validate={{ required: { value: true }, min: { value: 8 } }}
                            onChange={e => setTel(e.target.value)}
                        />
                      </div>
                    </Col>

                  </Row>


                  <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4 mt-4">
                    Diplôme :
                  </CardTitle>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Cycle : </Label>
                        <Select
                            options={cycle}
                            isDisabled={true}
                            isSearchable={true}
                            value={selectCycle}
                            onChange={e => getNiveau(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Niveau : </Label>
                        <Select
                            isDisabled={true}
                            options={niveau}
                            isSearchable={true}
                            value={selectNiveau}
                            onChange={e => getSpec(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Spécialité :</Label>
                        <Select
                            isDisabled={true}
                            options={specialite}
                            isSearchable={true}
                            value={selectSpecialite}
                            onChange={e => getGroupe(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Groupe : </Label>
                        <Select
                            isDisabled={true}
                            options={groupe}
                            isSearchable={true}
                            isMulti={true}
                            value={selectGroupe}
                            onChange={e => setSelectGroupe(e)}
                        />
                      </div>
                    </Col>
                  </Row>

                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="12">
                    <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                    >
                      <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                              props.history.push("/Etudiant")
                          }
                      >
                        Annuler
                      </Button>
                    </div>
                  </Col>

                </div>
              </AvForm>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailEtudiant

DetailEtudiant.propTypes = {
  history: PropTypes.object,
}
