import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Button,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader, Label,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const AddMatiere = props => {
  const [arrayMatiere, setArrayMatiere] = useState([
    {
      name: "",
      nbhCours: "",
      nbhTP: "",
    },
  ])
  const [modal, setModal] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")

  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
    { value: 5, label: "Formation" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")


  useEffect(async () => {

  }, [])

  const addLigne = () => {
    let element = {
      name: "",
      nbhCours: "",
      nbhTP: ""
    }
    setArrayMatiere([...arrayMatiere, element])
  }

  const toggle = indexOfLigne => {
    setModal(!modal)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayMatiere.splice(indexLigne, 1)
    setModal(false)
  }

  const addMatiere = (event, index) => {
    let matiere = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { name: matiere }) : el
        )
    )
  }
  const addNbhCours = (event, index) => {
    let nbhCours = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { nbhCours: nbhCours }) : el
        )
    )
  }
  const addNbhTP = (event, index) => {
    let nbhTP = event.target.value
    setArrayMatiere(
        arrayMatiere.map((el, id) =>
            id === index ? Object.assign(el, { nbhTP: nbhTP }) : el
        )
    )
  }

  const save = async () => {
    var etat = true
    for (let i = 0; i < arrayMatiere.length; i++) {
      var name = arrayMatiere[i].name
      var nbhCours = arrayMatiere[i].nbhCours
      var nbhTP = arrayMatiere[i].nbhTP
      if(nbhCours == ""){
        nbhCours=0;
        arrayMatiere[i].nbhCours=nbhCours
      }
      if(nbhTP == ""){
        nbhTP=0;
        arrayMatiere[i].nbhTP=nbhTP
      }
      if( selectSemestre == ""){
        var etat = false
        toast.error("⛔ Semestre Obligatoire ! ", {
          containerId: "A",
        })
      }else if ( name == ""){
        var etat = false
        toast.error("⛔ Saisir  le nom du matière", {
          containerId: "A",
        })
      }else  if((nbhCours == "")){
        var etat = false
        toast.error("⛔ Saisir au moins un nbh (cours , TD , TP ,Cours Intégrée)  ! ", {
          containerId: "A",
        })
      }
    }
    if (arrayMatiere.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins une matière", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("matiere/add", {
        ligne: arrayMatiere,
        semestre_id:selectSemestre.value
      }).then(res => {
        props.setSection(1)
      })
    }
  }

  return (
      <React.Fragment>
        <Row>
          {/*<CardTitle className="h4 mt-2">Ajouter matière</CardTitle>*/}
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Semestre</Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    value={selectSemestre}
                    onChange={e => setSelectSemestre(e)}
                />
              </div>
            </Col>
          </Row>
          <table className="table table-bordered mt-4">
            <thead>
            <tr>
              <th style={{ textAlign: "center" }} scope="col">
                Matière
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Nbh cours
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                Nbh tp
              </th>
              <th style={{ textAlign: "center" }} scope="col">
                <i
                    style={{
                      color: "#a0ceb8",
                      cursor: "pointer",
                    }}
                    className="fas fa-plus"
                    onClick={addLigne}
                />
              </th>
            </tr>
            </thead>
            {arrayMatiere.map((el, index) => (
                <tbody key={index}>
                <tr>
                  <td className="col-md-2">
                    <Input
                        style={{ textAlign: "center" }}
                        type="text"
                        className="form-control"
                        placeholder="Matière ..."
                        onChange={e => addMatiere(e, index)}
                    />
                  </td>

                  <td className="col-md-1">
                    <Input
                        style={{ textAlign: "center" }}
                        type="number"
                        className="form-control"
                        placeholder="nbh cours ..."
                        onChange={e => addNbhCours(e, index)}
                    />
                  </td>
                  <td className="col-md-1">
                    <Input
                        style={{ textAlign: "center" }}
                        type="number"
                        className="form-control"
                        placeholder="nbh tp ..."
                        onChange={e => addNbhTP(e, index)}
                    />
                  </td>
                  <td className="col-md-1" style={{ textAlign: "center" }}>
                    <i
                        style={{ color: "#cc0000" }}
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => toggle(index)}
                    />
                  </td>
                </tr>
                </tbody>
            ))}
          </table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className="text-center mt-4"
              >
                <Button
                    type="button"
                    color="warning"
                    className="btn btn-warning  mb-2 me-2"
                    onClick={props.back}
                >
                  Annuler
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  className="text-center mt-4"
              >
                <button type="button" className="btn btn-primary " onClick={save}>
                  CONFIRMER
                </button>
              </div>
            </Col>
          </div>
        </Row>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader toggle={toggle} tag="h4">
            {" Suppression d'une ligne"}{" "}
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Voulez-vous supprimer cette ligne ?</p>
              </div>
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      onClick={toggleDelete}
                      color="info"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggle}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}

export default AddMatiere
AddMatiere.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
