import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
const EditPaiementEtudiant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [typePiement, setTypePiement] = useState([]);
  const [selectTypePaiement, setSelectTypePaiement] = useState("");
  const [montant, setMontant] = useState("");
  const [numeroCheque, setNumeroCheque] = useState("");
  const [responsableCheque, setResponsableCheque] = useState("");
  const [dateCheque, setDateCheque] = useState("");
  const [nameBnque, setNameBnque] = useState("");
  const [etat, setEtat] = useState("none");
  const [etudiantId, setEtudiantId] = useState("");
  const [specialite, setSpecialite] = useState([]);
  const [selectSpecilaite, setSelectSpecilaite] = useState("");
  useEffect(async () => {
    API.get("type_payment/selectlist").then(resC => {
      setTypePiement(resC.data.type_payment)
    })
    setLoading(true)
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    const res = await API.post("ligne_payment/getbyid", {
      id: id,
    }).then(res => {
      setId(res.data.ligne_de_payment.id)
      setSelectTypePaiement(res.data.ligne_de_payment.type_payment)
      setMontant(res.data.ligne_de_payment.prix)
      setEtudiantId(res.data.ligne_de_payment.etudiant_id)
      setSelectSpecilaite(res.data.ligne_de_payment.specialite)
      if(res.data.ligne_de_payment.type_payment.value == 2){
        setEtat("none")
      }else if(res.data.ligne_de_payment.type_payment.value == 3){
        setEtat("")
        setNumeroCheque(res.data.ligne_de_payment.numero_cheque)
        setDateCheque(new Date(res.data.ligne_de_payment.date_cheque))
        setResponsableCheque(res.data.ligne_de_payment.responsable_cheque)
        setNameBnque(res.data.ligne_de_payment.name_banque)
      }
      setLoading(true)
    })
  }, [])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const getType = async event => {
    setSelectTypePaiement(event)
    if(event.value == 2){
      setEtat("none")
    }else if(event.value == 3){
      setEtat("")
    }
  }
  const save = async () => {
    if (dateCheque != "") {
      let dateComm = dateCheque
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }

    if (selectTypePaiement == "") {
      toast.error("⛔ Veuillez choisir un Type de paiement", {
        containerId: "A",
      })
    } else if (montant == "") {
      toast.error("⛔ Veuillez saisir un montant ", {
        containerId: "A",
      })
    }
    if(selectTypePaiement.value == 2){
      const res = await API.post("ligne_payment/update", {
        id:id,
        user_id:user_id,
        etudiant_id:etudiantId,
        prix:montant,
        type_payment_id:selectTypePaiement.value,
        specialite_id:selectSpecilaite.value
      }).then(res => {
        if(res.data.errNum ==400){
          toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
            containerId: "A",
          })
        }else{
          props.history.push("/Etudiant")
        }

      })
    }else if(selectTypePaiement.value == 3){
      if (convertDate == "") {
        toast.error("⛔ Veuillez choisir un une date de chèque ", {
          containerId: "A",
        })
      }else if(numeroCheque == ""){
        toast.error("⛔ Veuillez saisir un numéro de chèque ", {
          containerId: "A",
        })
      }else if(responsableCheque == ""){
        toast.error("⛔ Veuillez saisir un responsable de chèque ", {
          containerId: "A",
        })
      }else if( nameBnque == ""){
        toast.error("⛔ Veuillez saisir le nom du banque ", {
          containerId: "A",
        })
      }
      else{
        const res = await API.post("ligne_payment/update", {
          id:id,
          user_id:user_id,
          etudiant_id:etudiantId,
          type_payment_id:selectTypePaiement.value,
          numero_cheque:numeroCheque,
          responsable_cheque:responsableCheque,
          date_cheque:convertDate,
          name_banque:nameBnque,
          prix:montant,
          specialite_id:selectSpecilaite.value
        }).then(res => {
          if(res.data.errNum ==400){
            toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
              containerId: "A",
            })
          }else if(res.data.errNum ==404){
            toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
              containerId: "A",
            })
          }else{
            props.history.push("/Etudiant")
          }
        })
      }
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                            Paiement Etudiant :
                          </CardTitle>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité : </Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecilaite}
                                    isDisabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Type paiement : </Label>
                                <Select
                                    options={typePiement}
                                    isSearchable={true}
                                    value={selectTypePaiement}
                                    onChange={e => getType(e)}
                                    isDisabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Montant : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={montant}
                                    onChange={e => setMontant(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display:etat}}>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Chèque :
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={dateCheque}
                                      onChange={e => setDateCheque(e)}
                                      dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Numéro de chèque : </Label>
                                <AvField
                                    value={numeroCheque}
                                    name="numero_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNumeroCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display:etat}}>
                            <Col  lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Responsable de chèque : </Label>
                                <AvField
                                    value={responsableCheque}
                                    name="responsable_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setResponsableCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom du banque : </Label>
                                <AvField
                                    value={nameBnque}
                                    name="nom_banque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNameBnque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>props.history.push("/Etudiant")}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div style={{display: "flex",justifyContent: "flex-start",}} className="text-center mt-4">
                              <button onClick={save} type="submit" className="btn btn-primary ">CONFIRMER</button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditPaiementEtudiant
EditPaiementEtudiant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
