import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Container,
  Card,
  CardBody,
  Input,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../api"
import Select from "react-select"

const AddScheduleEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [loadingAdd, setLoadingAdd] = useState(true)
  const [enseignantId, setEnseignantId] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [salle, setSalle] = useState([])
  const [selectSalle, setSelectSalle] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [modal, setModal] = useState(false)
  const [jourId, setJourId] = useState("")
  const [quartId, setQuartId] = useState("")
  const [enseignantNom, setEnseignantNom] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [element, setElement] = useState("")
  const [modalUpdate, setModalUpdate] = useState("")
  const [seance, setSeance] = useState("")
  const [salleId, setSalleId] = useState("")
  const [semestre_id, setSemestre_id] = useState("")
  const [modalLigne, setModalLigne] = useState(false)
  const [indexLigne, setIndexLigne] = useState("")

  const [nbrHeure, setNbrHeure] = useState([
    { value: 1, label: "1h" },
    { value: 2, label: "2h" },
    { value: 3, label: "3h" },
  ])
  const [selectNbHeure, setSelectNbHeure] = useState("");
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id_semestre = array[1]
    var array1 = id_semestre.split("/")
    var id = array1[0]
    var semestre_id = array1[1]
    setEnseignantId(id)
    setSemestre_id(semestre_id)
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setEnseignantNom(resE.data.emploi[0].enseignant)
      setLoading(true)
    })
     API.post("matiere/get_by_enseignant_id", {
      enseignant_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setMatiere(resE.data.Matiere)

    })

  }, [])
  const toggleLigne = indexOfLigne => {
    setModalLigne(!modalLigne)
    setIndexLigne(indexOfLigne)
  }
  const toggleDeleteLigne = () => {
    array.splice(indexLigne, 1)
    setModalLigne(false)
  }
  const open = element => {
    setModalDelete(!modalDelete)
    setElement(element)
  }
  const openModal = async (elle, element) => {
    setModalUpdate(true)
    setSalleId(elle.id_salle)
    setSeance(elle)

    const resG = await API.post("emploi/get_group_cour_jour", {
      semestre_id: semestre_id,
      quart_id: elle.id_quart,
      jour_id: elle.id_jour,
      type: element.type,
      matiere_id: elle.matiere_id,
      quan: element.quan,
      enseignant_id: enseignantId,
      annee_id:annee_id
    }).then(resG => {
      setGroupe(resG.data.groups)
    })
    const resS = await API.post("emploi/get_salle", {
      semestre_id: semestre_id,
      quart_id: elle.id_quart,
      jour_id: elle.id_jour,
      quan: element.quan,
      annee_id:annee_id
    }).then(resS => {
      setSalle(resS.data.salles)
    })
  }
  const openModal1 = async () => {
    setModalUpdate(false)
    setSelectSalle("")
    setSelectGroupe("")
  }
  const toggle = (element, elem) => {
    setSelectMatiere("")
    setSelectNbHeure("")
    setSelectSalle("")
    setSelectGroupe("")
    setModal(!modal)
    setLoadingAdd(true)
    var jour_id = elem
    var quart_id = element.id_quart
    setJourId(jour_id)
    setQuartId(quart_id)
    API.post("emploi/get_salle", {
      semestre_id: semestre_id,
      quart_id: quart_id,
      jour_id: jour_id,
      annee_id:annee_id
    }).then(resS => {
      setSalle(resS.data.salles)
    })

  }
  const hover = (index, indice, ind) => {
    document.getElementById(
      "hover_td" + index + indice + ind
    ).style.transition = `0.7s ease all`
    document.getElementById("hover_td" + index + indice + ind).style.boxShadow =
      "rgb(0 0 0 / 20%) 0px 0px 20px 1px"
    document.getElementById("hover_td" + index + indice + ind).style.scale =
      "(0.9px)"
  }
  const leave = (index, indice, ind) => {
    document.getElementById("hover_td" + index + indice + ind).style.boxShadow =
      "none"
    document.getElementById("hover_td" + index + indice + ind).style.scale =
      "none"
    document.getElementById(
      "hover_td" + index + indice + ind
    ).style.transition = `none`
  }
  const save = async () => {
    if (selectMatiere == "") {
      toast.error("⛔ Matière obligatoire !", {
        containerId: "A",
      })
    } else if (selectNbHeure == "") {
      toast.error("⛔ Nb Heure obligatoire !", {
        containerId: "A",
      })
    } else if (selectSalle == "") {
      toast.error("⛔ Salle obligatoire !", {
        containerId: "A",
      })
    } else if (selectGroupe != "") {
      var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
      var annee_id = userAuthScolarite.user.annee_id
      setLoadingAdd(false)
      API.post("seance/add", {
        quart_id: quartId,
        jour_id: jourId,
        enseignant_id: enseignantId,
        salle_id: selectSalle.value,
        matiere_id:selectMatiere.value,
        nbr_heure:selectNbHeure.value,
        semestre_id: semestre_id,
        annee_id:annee_id,
        group_id:selectGroupe
      }).then(res => {
        setLoadingAdd(true)
        if(res.data.errNum == 403){
          toast.error("⛔ Le Groupe n'est pas disponible dans cette seance !", {
            containerId: "A",
          })
        }else{
          const resE = API.post("emploi/get_by_id", {
            enseignant_id: enseignantId,
            semestre_id: semestre_id,
            annee_id:annee_id
          }).then(resE => {
            setQuarts(resE.data.emploi[0].quarts)
            setJours(resE.data.emploi[0].jours)
            setEnseignantNom(resE.data.emploi[0].enseignant)
            setModal(false)
          })
        }

      })
    } else {
      toast.error("⛔ Groupe obligatoire !", {
        containerId: "A",
      })
    }
  }
  const edit = async () => {
    if (selectSalle == "") {
      var salle_id = salleId
    } else {
      var salle_id = selectSalle.value
    }
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("seance/update", {
      seance_id: seance.id_seance,
      salle_id: salle_id,
    }).then(res => {
      const resE = API.post("emploi/get_by_id", {
        enseignant_id: enseignantId,
        semestre_id: semestre_id,
        annee_id:annee_id
      }).then(resE => {
        setQuarts(resE.data.emploi[0].quarts)
        setJours(resE.data.emploi[0].jours)
        setEnseignantNom(resE.data.emploi[0].enseignant)
        setModalUpdate(!modalUpdate)
      })
    })
  }
  const deleteSeance = async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("seance/delete", {
      id: element.id_seance,
    }).then(res => {
      const resE = API.post("emploi/get_by_id", {
        enseignant_id: enseignantId,
        semestre_id: semestre_id,
        annee_id:annee_id
      }).then(resE => {
        setQuarts(resE.data.emploi[0].quarts)
        setJours(resE.data.emploi[0].jours)
        setModalDelete(!modalDelete)
      })
    })
  }
  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    setSelectMatiere(event)
    setSelectNbHeure("")
    setSelectSalle("")
    setSelectGroupe("")
    const resG = await API.post("emploi/get_group_cour_jour", {
      semestre_id: semestre_id,
      quart_id: quartId,
      jour_id:jourId,
      matiere_id: event.value,
      enseignant_id: enseignantId,
      annee_id:annee_id
    }).then(resG => {
      setGroupe(resG.data.Group)
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "13px",
                    }}
                  >
                    <span style={{ paddingRight: "4px" }}>Enseignant:</span>
                    <span style={{ fontWeight: "bold" }}>{enseignantNom}</span>
                  </div>
                 <div>
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered text-center">
                        <thead className="table table-light">
                          <tr>
                            <th></th>
                            {quarts.map(el => (
                              <th
                                key={el.id_quart}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {el.label_quart}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {jours.map((elem, index) => (
                            <tr key={elem.id_jour}>
                              <td style={{ fontWeight: "bold" }}>
                                {elem.label_jour}
                              </td>
                              {elem.seances.map((element, indice) =>
                                  element.count !=0 ? (
                                    <td style={{ padding: "0px" }} className="td-emploi">
                                      {element.options.map((elle, ind) => (

                                        <div key={indice}  onDoubleClick={() =>openModal(elle, element)}
                                          id={"hover_td" + index + indice + ind}
                                          onMouseEnter={() => hover(index, indice, ind)}
                                          onMouseLeave={() =>leave(index, indice, ind)}
                                          style={{
                                            padding: "2px 2px 2px 2px",
                                            cursor: "pointer",
                                            borderBottom:
                                              ind == 0 &&
                                              element.options.length > 1
                                                ? "1px solid #eff2f7"
                                                : null,
                                          }}
                                        >

                                          <div onClick={() => open(elle)} className="icon_remove" >
                                            <i style={{ color: "red", cursor: "pointer", }}    className="far fa-trash-alt"
                                            ></i>
                                          </div>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_matiere}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_group}
                                          </p>
                                          <p
                                              style={{
                                                textAlign: "center",
                                              }}
                                          >
                                            {elle.label_etudiant}
                                          </p>
                                          <p
                                            style={{
                                              textAlign: "center",
                                            }}
                                          >
                                            {elle.label_salle}
                                          </p>
                                            <div className="div-icon">
                                              <span className="icon-cour">
                                                C
                                              </span>
                                            </div>
                                        </div>
                                      ))}
                                    </td>

                                ) : (
                                  <td className="td-emploi">
                                    <div className="div-emploi">
                                      <button
                                        onClick={() =>
                                          toggle(element, elem.id_jour)
                                        }
                                        type="button"
                                        className="btn btn-info btn-link btn-just-icon btn-sm text-white"
                                        title="Ajouter Séance"
                                      >
                                        <i className="fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </td>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                            props.history.push("/SchedulesEnseignants")
                          }
                        >
                          Annuler
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    {"Chargement"} ...{" "}
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
      <Modal
        style={{
          maxWidth: "700px",
          width: "100%",
        }}
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={toggle}
          tag="h4"
        >
          Ajouter Séance
        </ModalHeader>
        <ModalBody>
          {loadingAdd ? (
            <div>
                <Fragment>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Matiere</Label>
                        <Select
                          options={matiere}
                          isSearchable={true}
                          value={selectMatiere}
                          onChange={e => getGroupe(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          Nbr heure
                        </Label>
                        <Select
                            options={nbrHeure}
                            isSearchable={true}
                            value={selectNbHeure}
                            onChange={e => setSelectNbHeure(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Salle</Label>
                        <Select
                            options={salle}
                            isSearchable={true}
                            value={selectSalle}
                            onChange={e => setSelectSalle(e)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">Groupe</Label>
                        <Select
                            options={groupe}
                            isSearchable={true}
                            isMulti={true}
                            value={selectGroupe}
                            onChange={e => setSelectGroupe(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Fragment>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={save}
                      >
                        CONFIRMER
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                {"Chargement"} ...{" "}
              </h4>
            </div>
          )}
        </ModalBody>
      </Modal>
      <Modal isOpen={modalDelete} toggle={open} centered={true}>
        <ModalHeader toggle={open} tag="h4">
          {" Suppression d'une seance"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette seance ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={deleteSeance}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button onClick={open} color="danger" className="btn-rounded ">
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          maxWidth: "700px",
          width: "100%",
        }}
        isOpen={modalUpdate}
        toggle={openModal1}
        centered={true}
      >
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
          }}
          toggle={openModal1}
          tag="h4"
        >
          Update Séance
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Salle</Label>
                <Select
                  options={salle}
                  isSearchable={true}
                  value={selectSalle}
                  onChange={e => setSelectSalle(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => setSelectGroupe(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={edit}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalLigne} toggle={toggleLigne} centered={true}>
        <ModalHeader toggle={toggleLigne} tag="h4">
          {" Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDeleteLigne}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleLigne}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default AddScheduleEnseignant
AddScheduleEnseignant.propTypes = {
  history: PropTypes.object,
}
