import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"

//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";


const EditPaiementEnseignant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [typePiement, setTypePiement] = useState([]);
  const [selectTypePaiement, setSelectTypePaiement] = useState("");
  const [montant, setMontant] = useState("");
  const [numeroCheque, setNumeroCheque] = useState("");
  const [responsableCheque, setResponsableCheque] = useState("");
  const [dateCheque, setDateCheque] = useState("");
  const [nameBnque, setNameBnque] = useState("");
  const [etat, setEtat] = useState("none");
  const [matiere, setMatiere] = useState([]);
  const [selectMatiere, setSelectMatiere] = useState("");
  const [enseignantId, setEnseignantId] = useState("");
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [montantTotal, setMontantTotal] = useState("");
  const [montantReste, setMontantReste] = useState("");
  const [montantPayee, setMontantPayee] = useState("");
  const [disbutReg, setDisbutReg] = useState(true)
  const [nbrHeure, setNbrHeure] = useState("");
  const [prixHeure, setPrixHeure] = useState("");
  const [montantAncien, setMontantAncien] = useState("");
  useEffect(async () => {
    API.get("type_payment/selectlist").then(resC => {
      setTypePiement(resC.data.type_payment)
    })
    setLoading(true)
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    API.post("enseignant/getsemester",{
      id:id,
      annee_id
    }).then(resM => {
      setMatiere(resM.data.Matieres)
    })
    const res = await API.post("ligne_payment/getbyidligne_payment", {
      id: id,
    }).then(res => {
      setId(res.data.ligne_de_payment.id)
      setSelectTypePaiement(res.data.ligne_de_payment.type_payment)
      setMontant(res.data.ligne_de_payment.prix)
      setMontantAncien(res.data.ligne_de_payment.prix)
      setSelectMatiere(res.data.ligne_de_payment.matiere)
      setEnseignantId(res.data.ligne_de_payment.enseignant_id)
      setSelectSpecialite(res.data.ligne_de_payment.specialite)
      if(res.data.ligne_de_payment.type_payment.value == 2){
        setEtat("none")
      }else if(res.data.ligne_de_payment.type_payment.value == 3){
        setEtat("")
        setNumeroCheque(res.data.ligne_de_payment.numero_cheque)
        setDateCheque(new Date(res.data.ligne_de_payment.date_cheque))
        setResponsableCheque(res.data.ligne_de_payment.responsable_cheque)
        setNameBnque(res.data.ligne_de_payment.name_banque)
      }
      API.post("ligne_payment/get_prix_heure",{
        matiere_id:res.data.ligne_de_payment.matiere.value,
        specialite_id:res.data.ligne_de_payment.specialite.value,
        enseignant_id:res.data.ligne_de_payment.enseignant_id
      }).then(resC => {
        // if(resC.data.prix_heure.rest_paye == 0){
        //   setDisbutReg(false)
        // }else{
        //   setDisbutReg(true)
        // }
        setNbrHeure(resC.data.prix_heure.nb_heure)
        setPrixHeure(resC.data.prix_heure.prix_heure)
        setMontantTotal(resC.data.prix_heure.prix_total)
        setMontantPayee(resC.data.prix_heure.prix_paye)
        setMontantReste(resC.data.prix_heure.rest_paye)
      })
      setLoading(true)
    })
  }, [])
  const handleKeyUp = (event) => {
    setPrixHeure(event.target.value)
    setMontantTotal(event.target.value * nbrHeure)
    setMontantReste((event.target.value * nbrHeure)-montantPayee)
    if((event.target.value * nbrHeure)-montantPayee == 0 || (event.target.value * nbrHeure)-montantPayee > 0){
      setDisbutReg(true)
    }else{
      setDisbutReg(false)
    }
  };
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const getType = async event => {
    setSelectTypePaiement(event)
    if(event.value == 2){
      setEtat("none")
    }else if(event.value == 3){
      setEtat("")
    }
  }
  const save = async () => {
    if (dateCheque != "") {
      let dateComm = dateCheque
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }

    if (selectTypePaiement == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez choisir un Type de paiement", {
        containerId: "A",
      })
    } else if (montant == "") {
      setDisbutReg(true)
      toast.error("⛔ Veuillez saisir un montant ", {
        containerId: "A",
      })
    }else{
      if( (montant-montantAncien) <= montantReste || (montantTotal- montantPayee) <= montant){
        setDisbutReg(false)
        if(selectTypePaiement.value == 2){
          const res = await API.post("ligne_payment/updateligne", {
            id:id,
            enseignant_id:enseignantId,
            matiere_id:selectMatiere.value,
            type_payment_id:selectTypePaiement.value,
            specialite_id:selectSpecialite.value,
            prix_heure:prixHeure,
            user_id:user_id,
            prix:montant,
          }).then(res => {
            if(res.data.errNum ==400){
              setDisbutReg(true)
              toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
                containerId: "A",
              })
            }else{
              props.history.push("/Enseignant")
            }

          })
        }
        else if(selectTypePaiement.value == 3){
          if (convertDate == "") {
            setDisbutReg(true)
            toast.error("⛔ Veuillez choisir une date de chèque ", {
              containerId: "A",
            })
          }else if(numeroCheque == ""){
            setDisbutReg(true)
            toast.error("⛔ Veuillez saisir un numéro de chèque ", {
              containerId: "A",
            })
          }else if(responsableCheque == ""){
            setDisbutReg(true)
            toast.error("⛔ Veuillez saisir un responsable de chèque ", {
              containerId: "A",
            })
          }else if( nameBnque == ""){
            setDisbutReg(true)
            toast.error("⛔ Veuillez saisir le nom du banque ", {
              containerId: "A",
            })
          }
          else{
            const res = await API.post("ligne_payment/updateligne", {
              id:id,
              user_id:user_id,
              enseignant_id:enseignantId,
              matiere_id:selectMatiere.value,
              type_payment_id:selectTypePaiement.value,
              numero_cheque:numeroCheque,
              responsable_cheque:responsableCheque,
              date_cheque:convertDate,
              name_banque:nameBnque,
              prix:montant,
              specialite_id:selectSpecialite.value,
              prix_heure:prixHeure
            }).then(res => {
              if(res.data.errNum ==400){
                setDisbutReg(true)
                toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
                  containerId: "A",
                })
              }else if(res.data.errNum ==404){
                setDisbutReg(true)
                toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
                  containerId: "A",
                })
              }else{
                props.history.push("/Enseignant")
              }
            })
          }
        }
      }else{

        setDisbutReg(true)
        toast.error("⛔ Vous avez dépassée le reste du montant ! ", {
          containerId: "A",
        })
      }

    }

  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                           Modification Paiement Enseignant :
                          </CardTitle>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant total : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantTotal}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Total payée : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantPayee}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="2">
                              <div className="mb-2">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Reste à payée: </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantReste}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="2">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nombre Heure : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={nbrHeure}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="2">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Prix Heure : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={prixHeure}
                                    onKeyUp={handleKeyUp}
                                    disabled={true}
                                    // onChange={e => setPrixHeure(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecialite}
                                    onChange={e => getMatiere(e)}
                                    isDisabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Matière : </Label>
                                <Select
                                    options={matiere}
                                    isSearchable={true}
                                    value={selectMatiere}
                                    isDisabled={true}
                                    onChange={e => setSelectMatiere(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Type paiement : </Label>
                                <Select
                                    options={typePiement}
                                    isSearchable={true}
                                    value={selectTypePaiement}
                                    isDisabled={true}
                                    onChange={e => getType(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Montant : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    value={montant}
                                    className="form-control"
                                    onChange={e => setMontant(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display:etat}}>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Chèque :
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={dateCheque}
                                      onChange={e => setDateCheque(e)}
                                      dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Numéro de chèque : </Label>
                                <AvField
                                    name="numero_cheque"
                                    type="text"
                                    className="form-control"
                                    value={numeroCheque}
                                    onChange={e => setNumeroCheque(e.target.value)}
                                />
                              </div>
                            </Col>

                          </Row>
                          <Row style={{display:etat}}>
                            <Col  lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Responsable de chèque : </Label>
                                <AvField
                                    name="responsable_cheque"
                                    type="text"
                                    className="form-control"
                                    value={responsableCheque}
                                    onChange={e => setResponsableCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom du banque : </Label>
                                <AvField
                                    name="nom_banque"
                                    type="text"
                                    className="form-control"
                                    value={nameBnque}
                                    onChange={e => setNameBnque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>props.history.push("/Enseignant")}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div style={{display: "flex",justifyContent: "flex-start",}} className="text-center mt-4">
                              <button onClick={save}
                                      id="Confirmer"
                                      name="Confirmer"
                                      type="button"
                                      color="primary"
                                      className="btn btn-primary mb-2 me-2"
                                      disabled={!disbutReg}>CONFIRMER</button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditPaiementEnseignant
EditPaiementEnseignant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
