import React, {useEffect, useMemo, useState} from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
  Spinner, Form, CardTitle, Label, Input, TabPane, TabContent,
} from "reactstrap"
import API from "../../api"
import countryList from "react-select-country-list";
import user1 from "../../assets/images/users/userImage.png"
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import Select from "react-select";
const DetailEnseignantPaiement = props => {
  const [id, setId] = useState("")
  const [montantTotal, setMontantTotal] = useState("");
  const [montantReste, setMontantReste] = useState("");
  const [montantPayee, setMontantPayee] = useState("");
  const [detail, setDetail] = useState("");
  const [loading, setLoading] = useState(false)
  const [arrayDetail, setArrayDetail] = useState([])
  const [matiere, setMatiere] = useState("");
  const [specialite, setSpecialite] = useState("");
  const [nbrHeure, setNbrHeure] = useState("");
  const [prixHeure, setPrixHeure] = useState("");
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("payment/get_detail_ligne_payment_ens", {
      id: id,
    }).then(res => {
      setId(res.data.payment_detail.id)
      setMontantPayee(res.data.payment_detail.payment.prix_paye)
      setMontantReste(res.data.payment_detail.payment.prix_à_paye)
      setMontantTotal(res.data.payment_detail.payment.prix_total)
      setMatiere(res.data.payment_detail.payment.matiere)
      setNbrHeure(res.data.payment_detail.payment.nb_heure)
      setPrixHeure(res.data.payment_detail.payment.prix_heure)
      setSpecialite(res.data.payment_detail.payment.specialite)
      setDetail(res.data.payment_detail.type_prix)
      setArrayDetail(res.data.payment_detail.ligne_payment)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consultation Enseignant | Cresus </title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
        {loading ? (

          <Container fluid={true}>
            <Row>
              <AvForm>
                <Form>
                  <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                    Détail paiement Enseignant :
                  </CardTitle>
                  <Row>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant total : </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={montantTotal}
                            disabled={true}
                        />
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Total payée : </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={montantPayee}
                            disabled={true}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="mb-2">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Reste à payée: </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={montantReste}
                            disabled={true}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Nombre Heure : </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={nbrHeure}
                            disabled={true}
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Prix Heure : </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={prixHeure}
                            disabled={true}
                            // onChange={e => setPrixHeure(e.target.value)}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Matière: </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={matiere}
                            disabled={true}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label style={{ color: "red" }} for="basicpill-firstname-input1">Spécialité: </Label>
                        <AvField
                            name="montant"
                            placeholder="0.000"
                            type="text"
                            className="form-control"
                            value={specialite}
                            disabled={true}
                        />
                      </div>
                    </Col>
                  </Row>
                  <table className="table table-bordered mt-4">
                    <thead>
                    <tr>

                      <th style={{ textAlign: "center" }} scope="col">
                        Type Paiement
                      </th>

                      <th style={{ textAlign: "center" }} scope="col">
                        Montant
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Numéro du chèque
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Date du chèque
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Nom du banque
                      </th>
                      <th style={{ textAlign: "center" }} scope="col">
                        Responsable du chèque
                      </th>
                    </tr>
                    </thead>
                    {arrayDetail.map((el, index) => (
                        <tbody key={index}>
                        <tr>

                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.type_payment_id}
                          </td>

                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.prix}
                          </td>
                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.numero_cheque}
                          </td>
                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.date_cheque}
                          </td>
                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.name_banque}
                          </td>
                          <td style={{ textAlign: "center" }} className="col-md-2">
                            {el.responsable_cheque}
                          </td>
                        </tr>
                        </tbody>
                    ))}
                  </table>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="12">
                    <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                    >
                      <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                              props.history.push("/Enseignant")
                          }
                      >
                        Annuler
                      </Button>
                    </div>
                  </Col>

                </div>
              </AvForm>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DetailEnseignantPaiement

DetailEnseignantPaiement.propTypes = {
  history: PropTypes.object,
}
