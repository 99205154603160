import axios from "axios"
if (localStorage.getItem("userAuthEnseignant")) {
  var userAuthEnseignant = JSON.parse(localStorage.getItem("userAuthEnseignant"))
  var token = userAuthEnseignant
}
export default axios.create({
  withCredentials: true,
  baseURL: `https://ismsf.enseignant.backcresus-institut.ovh/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
    'X-Frame-Options' : "DENY"
  },
})
