import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"
import Select from "react-select"
import { Row, Col, CardTitle, Label, Form, Button } from "reactstrap"
import { withRouter } from "react-router"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import FileDownload from "js-file-download"

const ExportEtudiant = props => {
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [active, setActive] = useState([
    { value: 0, label: "Non Actif" },
    { value: 1, label: "Actif" },
  ])
  const [selectActive, setSelectActive] = useState("")
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage : 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )

  const Columns = () => [
    {
      dataField: "cin",
      text: "CIN",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "nom",
      text: "Nom et prénom",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: "Groupe",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "mobile",
      text: "Téléphone",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
  ]

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  }

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  )

  const { SearchBar } = Search

  useEffect(async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("etudiant/list_export",{
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.etudiants)
    })
    const resS = await API.get("specialite/get_by_diplomes").then(resS => {
      setSpecialite(resS.data.Specialite)
    })
  }, [])

  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var spec_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/get_by_spec", {
      id: spec_id,
      annee_id:annee_id
    }).then(res => {
      setGroupe(res.data.Niveau_by_group)
    })
  }

  const search = async () => {
    if (selectSpecialite != "") {
      var specialite_id = selectSpecialite.value
    } else {
      var specialite_id = 0
    }
    if (selectGroupe != "") {
      var groupe = selectGroupe
    } else {
      var groupe = 0
    }
    if (selectActive != "") {
      var active = selectActive.value
    } else {
      var active = 2
    }
    const res = await API.post("etudiant/search", {
      id: specialite_id,
      groups: groupe,
      active: active,
    }).then(res => {
      setOrders(res.data.etudiants)
    })
  }

  const searchAll = async () => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("etudiant/list_export",{
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.etudiants)
    })
  }

  const upload = async () => {
    if (selectSpecialite != "") {
      var specialite_id = selectSpecialite.value
    } else {
      var specialite_id = 0
    }
    if (selectGroupe != "") {
      var groupe = selectGroupe
    } else {
      var groupe = 0
    }
    if (selectActive != "") {
      var active = selectActive.value
    } else {
      var active = 2
    }
    const res = await API.post(
      "etudiant/export",
      {
        id: specialite_id,
        groups: groupe,
        active: active,
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "etudiants.xlsx")
    })
  }

  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Spécialité :</Label>
                <Select
                  options={specialite}
                  isClearable={true}
                  isSearchable={true}
                  onChange={e => getGroupe(e)}
                  formatGroupLabel={formatGroupLabel}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Groupe :</Label>
                <Select
                  isMulti={true}
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => setSelectGroupe(e)}
                  formatGroupLabel={formatGroupLabel}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Etat etudiant :</Label>
                <Select
                  options={active}
                  isClearable={true}
                  isSearchable={true}
                  onChange={e => setSelectActive(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          className="mb-4"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {" "}
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={search}
              >
                Rechercher
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={searchAll}
              >
                Afficher Tout
              </button>
            </div>
          </Col>
        </div>
        <Row>
          <Col xs="12">
            <ToolkitProvider
              keyField="id"
              data={orders}
              columns={Columns()}
              search
              bootstrap4
            >
              {toolkitProps => (
                <div>
                  <Row className="mb-2">
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="8">
                      <div className="text-sm-end">
                        <Button
                          type="button"
                          color="success"
                          className="btn  mb-2 me-2"
                          onClick={upload}
                        >
                          <i className="fas fa-file-export me-1" />
                          Export
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <BootstrapTable
                    wrapperClasses="table-responsive"
                    noDataIndication={() => <NoDataIndication />}
                    striped={false}
                    bordered={false}
                    classes={"table align-middle table-nowrap"}
                    headerWrapperClasses={"table-light"}
                    hover
                    pagination={paginationFactory(pageOptions)}
                    {...toolkitProps.baseProps}
                  />
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(ExportEtudiant)
ExportEtudiant.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
