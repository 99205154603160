import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
    Row,
    Col,
    Spinner,
    Modal,
    ModalBody,
    Label,
    Input,
    Button,
    Form,
    ModalHeader,
} from "reactstrap"
import {withRouter} from "react-router-dom"
import DataTable from "react-data-table-component"
import {withTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import Switch from "react-switch"
import APIE from "../../apie"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {Search} from "react-bootstrap-table2-toolkit";

const DataTableEtudiant = props => {
    const [loading, setLoading] = useState(false)

    const [modal, setModal] = useState(false)
    const [id, setId] = useState("")
    const [selectAnnee, setSelectAnnee] = useState("");

    const [modal1, setModal1] = useState(false)
    const [etudiantId, setEtudiantId] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [orders, setOrders] = useState([])
    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        sizePerPage: 30,
        alwaysShowAllBtns: true,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }
    const Columns = () => [
        {
            dataField: "cin",
            text: "CIN",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "nom",
            text: "Nom et prénom",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "email",
            text: "Email",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "mobile",
            text: "Téléphone",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "Paiement",
            isDummyField: true,
            text: "Paiement",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={"/PaiementEtudiant?id=" + row.id}>
                        <i
                            style={{ color: "red", cursor: "pointer"}}
                            className="fas fa-users-cog"
                        ></i>
                    </Link>
                </div>
            ),
        },
        {
            dataField: "DetailPaiement",
            isDummyField: true,
            text: "Détail Paiement",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={"/DetailPaiementEtudiant?id=" + row.id}>
                        <i
                            style={{  color: "green", cursor: "pointer"}}
                            className="fas fa-user-cog"
                        ></i>
                    </Link>
                </div>
            ),
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Attestation d'inscription",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    href={"https://backscolarite.esps.com.tn/api/etudiant/getbyid/" +row.id }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#5d6273", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Attestation de paiement",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    href={"https://backscolarite.esps.com.tn/api/etudiant/get_by_id/" +row.id }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#8193ec", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Attestation de présence FR",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    href={"https://backscolarite.esps.com.tn/api/etudiant/attestation_presence/" +row.id }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#cbab14", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "id",
            isDummyField: true,
            text: "Attestation de présence AR",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a
                    href={"https://backscolarite.esps.com.tn/api/etudiant/attestation_presence_arabe/" +row.id }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                    <i
                        style={{ color: "#d052de", cursor: "pointer" }}
                        className="fas fa-file-download"
                    ></i>
                </a>
            ),
        },
        {
            dataField: "Consultation",
            isDummyField: true,
            text: "Consultation",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={"/DetailEtudiant?id=" + row.id}>
                        <i
                            style={{ color: "mediumslateblue", cursor: "pointer" }}
                            className="fas fa-eye"
                        ></i>
                    </Link>
                </div>
            ),
        },
        {
            dataField: "Modification",
            isDummyField: true,
            text: "Modification",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link to={"/EditEtudiant?id=" + row.id}>
                        <i
                            style={{ color: "cornflowerblue", cursor: "pointer" }}
                            className="fas fa-edit"
                        ></i>
                    </Link>
                </div>
            ),
        },
        {
            dataField: "suppression",
            isDummyField: true,
            text: "Suppression",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                <div>
                    {row.active == 0 ? (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#ED6464", cursor: "pointer"}}
                                onClick={() => toggle(row.id)}
                                className="fas fa-trash-alt"
                            ></i>
                        </div>
                    ) : (
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <i
                                style={{color: "#A7A3A3", cursor: "pointer"}}
                                className="fas fa-trash-alt"
                                title="Cet Etudianr déja utilisé"
                            ></i>
                        </div>
                    )}
                </div>
            ),

        },
        {
            dataField: "active",
            isDummyField: true,
            text: "Etat",
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (colIndex, row) => (
                <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#34c38f"
                    onChange={() => activeEtudiant(row)}
                    checked={row.active}
                />
            ),
        },
    ]

    const Offsymbol = () => {
        return (
            <div
                title="Activé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                A
            </div>
        )
    }

    const OnSymbol = props => {
        return (
            <div
                title="Désactivé"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                D
            </div>
        )
    }

    const toggle = id => {
        setModal(!modal)
        setId(id)
    }

    const toggleDelete = async () => {
        const res = await API.post("etudiant/delete", {
            id: id,
        }).then(res => {
            setModal(false)
            var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
            var annee_id = userAuthScolarite.user.annee_id
            setSelectAnnee(userAuthScolarite.user.annee_id)
            API.post("etudiant/liste", {
                annee_id: annee_id
            }).then(resEtd => {
                setOrders(resEtd.data.etudiants)
                setLoading(true)
            })
        })
    }

    useEffect(async () => {
        var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
        var annee_id = userAuthScolarite.user.annee_id
        setSelectAnnee(userAuthScolarite.user.annee_id)
        API.post("etudiant/liste", {
            annee_id: annee_id
        }).then(res => {
            setOrders(res.data.etudiants)
            setLoading(true)
        })
    }, [])

    const activeEtudiant = async row => {
        var active = row.active
        var etudiantId = row.id
        if (active == 0) {
            var active = 1
            const res = await API.post("etudiant/activer", {
                id: etudiantId,
                active: active,
            }).then(res => {
                toast.success(" 🔓✔️ Etudaint Activé Avec succès", {
                    containerId: "A",
                })
                var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
                var annee_id = userAuthScolarite.user.annee_id
                setSelectAnnee(userAuthScolarite.user.annee_id)
                API.post("etudiant/liste", {
                    annee_id: annee_id
                }).then(res => {
                    setOrders(res.data.etudiants)
                    setLoading(true)
                })
            })
        } else if (active == 1) {
            var active = 0
            const res = await API.post("etudiant/activer", {
                id: etudiantId,
                active: active,
            }).then(res => {
                toast.success(" 🔒❌ Etudaint Désactivé Avec succès ", {
                    containerId: "A",
                })
                var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
                var annee_id = userAuthScolarite.user.annee_id
                setSelectAnnee(userAuthScolarite.user.annee_id)
                API.post("etudiant/liste", {
                    annee_id: annee_id
                }).then(res => {
                    setOrders(res.data.etudiants)
                    setLoading(true)
                })
            })
        }
    }

    const edit = async etudiantId => {
        var id = etudiantId.id
        const res = await APIE.post("user/update", {
            id: id,
            email: email,
            password: password,
        }).then(res => {
            setModal1(false)
        })
    }

    const activate = async etudiantId => {
        var id = etudiantId.id
        var active = etudiantId.active_compte
        if (active == 0) {
            var active = 1
            const res = await APIE.post("user/activate", {
                id: id,
                active: active,
                annee_id:selectAnnee
            }).then(res => {
                setModal1(false)
                toast.success(" 🔓✔️ Compte Activé Avec succès", {
                    containerId: "A",
                })
                var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
                var annee_id = userAuthScolarite.user.annee_id
                setSelectAnnee(userAuthScolarite.user.annee_id)
                API.post("etudiant/liste", {
                    annee_id: annee_id
                }).then(res => {
                    setOrders(res.data.etudiants)
                    setLoading(true)
                })
            })
        } else if (active == 1) {
            var active = 0
            const res = await APIE.post("user/activate", {
                id: id,
                active: active,
                annee_id:selectAnnee
            }).then(res => {
                setModal1(false)
                toast.success(" 🔒❌ Compte Désactivé Avec succès", {
                    containerId: "A",
                })
                var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
                var annee_id = userAuthScolarite.user.annee_id
                setSelectAnnee(userAuthScolarite.user.annee_id)
                API.post("etudiant/liste", {
                    annee_id: annee_id
                }).then(res => {
                    setOrders(res.data.etudiants)
                    setLoading(true)
                })
            })
        }
    }
    const { SearchBar } = Search
    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <Row className="mt-4">
                        <Col xs="12">
                            <ToolkitProvider
                                keyField="id"
                                data={orders}
                                columns={Columns()}
                                search
                                bootstrap4
                            >
                                {toolkitProps => (
                                    <div>
                                        <div>
                                            <div className="mb-3 row" style={{ display: "flex" }}>
                                                <div className="col-md-6 mb-3 row">
                                                    <div className="col-md-8 search-box-etd">
                                                        <div
                                                            className="search-box me-2 mb-2 d-inline-block"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className="position-relative">
                                                                <SearchBar {...toolkitProps.searchProps} />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <BootstrapTable
                                            wrapperClasses="table-responsive"
                                            noDataIndication={() => <NoDataIndication />}
                                            striped={false}
                                            bordered={false}
                                            classes={"table align-middle table-nowrap"}
                                            headerWrapperClasses={"table-light"}
                                            hover
                                            pagination={paginationFactory(pageOptions)}
                                            {...toolkitProps.baseProps}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {" "}
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            {props.t("Chargement en cours...")}
                        </h4>
                    </div>
                )}
            </div>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader
                    style={{width: "100% !important", textAlign: "center !important"}}
                    toggle={toggle}
                    tag="h4"
                >
                    {" Suppression etudiant"}{" "}
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <p>Voulez-vous supprimer cet etudiant ?</p>
                        </div>
                        <div
                            className="hvr-push"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button
                                    color="info"
                                    className="btn-rounded "
                                    onClick={toggleDelete}
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggle}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{color: "white"}}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(DataTableEtudiant))
DataTableEtudiant.propTypes = {
    orders: PropTypes.array,
    history: PropTypes.object,
    t: PropTypes.any,
}
