import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner } from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import { Link } from "react-router-dom"

const DataTableSchedulesGrp = props => {
  const [annee, setAnnee] = useState("");
  var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
  )
  var annee_id = userAuthScolarite.user.annee_id
  // setAnnee(annee_id)
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    sizePerPage: 50,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )



  const Columns = () => [
    {
      dataField: "group_cour",
      text: "Group Cour",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cycle_name",
      text: "Cycle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Emploi 1 ère Semestre",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/EmploiGroup?id=" + row.id + "/" + props.semestre}>
            <i
              style={{
                color: "green",
                cursor: "pointer",
              }}
              className="fas fa-eye"
            ></i>
          </Link>
        </div>
      ),
    },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
          {props.section == 2 ? (
        <a
          href={
            "https://backscolarite.esps.com.tn/api/seance/soire/impression/" +row.group_id+"/"+props.semestre+"/"+annee_id
          }
          target="_blank"
          rel="noreferrer"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i
            style={{ color: "#955cd5", cursor: "pointer" }}
            onClick={""}
            className="fas fa-file-download"
          ></i>
        </a>
            ):(
              <a
                  href={
                      "https://backscolarite.esps.com.tn/api/impression_group_shedules/" +row.id+"/"+props.semestre+"/"+annee_id
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ display: "flex", justifyContent: "center" }}
              >
                <i
                    style={{ color: "#955cd5", cursor: "pointer" }}
                    onClick={""}
                    className="fas fa-file-download"
                ></i>
              </a>
            )}
          </div>
      ),
    },
  ]
  const Columns2 = () => [
    {
      dataField: "group_cour",
      text: "Group Cour",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cycle_name",
      text: "Cycle",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    // {
    //   dataField: "id",
    //   isDummyField: true,
    //   text: "Téléchargement",
    //   style: { textAlign: "center" },
    //   headerStyle: (colum, colIndex) => {
    //     return { textAlign: "center" }
    //   },
    //   sort: true,
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //       {props.section == 2 ? (
    //     <a
    //       href={
    //         "https://backscolarite.esps.com.tn/api/seance/soire/impression/" +row.group_id+"/"+annee_id+"/"+props.semestre
    //       }
    //       target="_blank"
    //       rel="noreferrer"
    //       style={{ display: "flex", justifyContent: "center" }}
    //     >
    //       <i
    //         style={{ color: "#955cd5", cursor: "pointer" }}
    //         onClick={""}
    //         className="fas fa-file-download"
    //       ></i>
    //     </a>
    //         ):(
    //           <a
    //               href={
    //                   "https://backscolarite.esps.com.tn/api/impression_group_shedules/" +
    //                   row.id+"/"+annee_id
    //               }
    //               target="_blank"
    //               rel="noreferrer"
    //               style={{ display: "flex", justifyContent: "center" }}
    //           >
    //             <i
    //                 style={{ color: "#955cd5", cursor: "pointer" }}
    //                 onClick={""}
    //                 className="fas fa-file-download"
    //             ></i>
    //           </a>
    //         )}
    //       </div>
    //   ),
    // },
    {
      dataField: "id",
      isDummyField: true,
      text: "Téléchargement",
      style: { textAlign: "center" },
      headerStyle: (column, colIndex) => {
        return { textAlign: "center" };
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter:(cellContent, row) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {props.section === 2 ? (
                <a
                    href={
                        "https://backscolarite.esps.com.tn/api/seance/soire/impression/" +row.group_id +"/" + props.semestre+"/" +annee_id
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                  <i
                      style={{ color: "#955cd5", cursor: "pointer" }}
                      className="fas fa-file-download"
                  ></i>
                </a>
            ) : (
                <a
                    href={
                        "https://backscolarite.esps.com.tn/api/impression_group_shedules/" + row.id +"/"+ props.semestre +"/"+ annee_id
                    }
                    target="_blank"
                    rel="noreferrer"
                    style={{ display: "flex", justifyContent: "center" }}
                >
                  <i
                      style={{ color: "#955cd5", cursor: "pointer" }}
                      className="fas fa-file-download"
                  ></i>
                </a>
            )}
          </div>
      ),
    }

  ]


  const { SearchBar } = Search
  useEffect(async () => {
    const res = await API.post("emploi/group/list", {
      semestre_id: props.semestre,annee_id
    }).then(res => {
      setOrders(res.data.groups)
    })
    setLoading(true)
  }, [props.semestre])

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row className="mt-4">
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={props.semestre == 5 ? (Columns2()):(Columns())}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <div>
                      <div className="mb-3 row" style={{ display: "flex" }}>
                        <div className="col-md-6 mb-3 row">
                          <div className="col-md-8 search-box-etd">
                            <div
                              className="search-box me-2 mb-2 d-inline-block"
                              style={{ width: "100%" }}
                            >
                              <div className="position-relative">
                                <SearchBar {...toolkitProps.searchProps} />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="col-md-6 mb-3 row"
                        >
                          <div
                            style={{ textAlign: "right" }}
                            className="col-md-8 select-action"
                          >
                            <a
                              href={
                                "https://backscolarite.esps.com.tn/api/impression_groups_shedules" +
                                "/" +
                                props.semestre+"/"+annee_id
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <button className="btn btn-primary btn btn-primary">
                                <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                Emplois groupes
                              </button>
                            </a>
                          </div>
                          {/*<div*/}
                          {/*  style={{ textAlign: "right" }}*/}
                          {/*  className="col-md-4 select-action"*/}
                          {/*>*/}
                          {/*  <a*/}
                          {/*    href={*/}
                          {/*      "https://backscolarite.esps.com.tn/api/emploi/export" + "/" +*/}
                          {/*      props.semestre*/}
                          {/*    }*/}
                          {/*    target="_blank"*/}
                          {/*    rel="noreferrer"*/}
                          {/*  >*/}
                          {/*    <button className="btn btn-primary btn btn-success">*/}
                          {/*      <i className="fas fa-file-export font-size-16 align-middle me-2"></i>*/}
                          {/*      Excel Seances*/}
                          {/*    </button>*/}
                          {/*  </a>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withRouter(DataTableSchedulesGrp)
DataTableSchedulesGrp.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
  semestre: PropTypes.any,
  section:PropTypes.any
}
