import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  CardTitle,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import {toast} from "react-toastify";

const ViewGroupCour = props => {
  const [selectSemestre, setSelectSemestre] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [effectif, setEffectif] = useState("")
  const [nbrCours, setNbrCours] = useState("")
  const [arrayGroupe, setArrayGroupe] = useState([])
  const [idGroup, setIdGroup] = useState("");
  const [loading, setLoading] = useState(true)
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const res = await API.post("group/get_by_id", { cyc_niv_sem_spec_id :id,annee_id:annee_id }).then(res => {
      setSelectNiveau(res.data.Group.niveau)
      setSelectSemestre(res.data.Group.semestre)
      setSelectSpecialite(res.data.Group.specialite)
      setEffectif(res.data.Group.effectif)
      setIdGroup(res.data.Group.group_id)
      setNbrCours(res.data.Group.cours)
      setArrayGroupe(res.data.Group.group_cours)
      setLoading(true)
    })
  }, [])
  const addNomGroupe = (event, index) => {
    let NomGroupe = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index ? Object.assign(el, { NomGroupe: NomGroupe }) : el
        )
    )
  }
  const addCapacite = (event, index) => {
    let capacite = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index ? Object.assign(el, { capacite: capacite }) : el
        )
    )
  }
  const addNombreEtudiant = (event, index) => {
    let nombreEtudiant = event.target.value
    setArrayGroupe(
        arrayGroupe.map((el, id) =>
            id === index
                ? Object.assign(el, { nombreEtudiant: nombreEtudiant })
                : el
        )
    )
  }
  const save = async () => {
    API.post("group/edit", {
      effectif: effectif,
      group_id:idGroup,
      groupsCours: arrayGroupe,
    }).then(res => {
      props.history.push("/Groupe")
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Semestre:
                          </Label>
                          <Select
                            value={selectSemestre}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">Niveau</Label>
                          <Select value={selectNiveau} isSearchable={true} />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Spécialité
                          </Label>
                          <Select
                            value={selectSpecialite}
                            isSearchable={true}
                          />
                        </div>
                      </Col>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Effectif :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Effectif"
                            type="number"
                            onChange={e => setEffectif(e.target.value)}
                            value={effectif}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <div className="mb-3">
                          <Label for="basicpill-firstname-input1">
                            Nombre de groupe cours :
                          </Label>
                          <Input
                            className="form-control"
                            placeholder="Nombre de groupe cours"
                            type="number"
                            value={nbrCours}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <CardTitle className="h4 mt-4">Groupe de cours</CardTitle>
                  <table className="table table-bordered mt-4">
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nom groupe
                        </th>

                        <th style={{ textAlign: "center" }} scope="col">
                          Capacité
                        </th>
                        <th style={{ textAlign: "center" }} scope="col">
                          Nombre etudiant
                        </th>
                      </tr>
                    </thead>
                    {arrayGroupe.map((el, index) => (
                      <tbody key={index}>
                        <tr>
                          <td className="col-md-2">
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Nom Groupe"
                              onChange={e => addNomGroupe(e, index)}
                              value={el.NomGroupe}
                            />
                          </td>

                          <td className="col-md-2">
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Capacité"
                                onChange={e => addCapacite(e, index)}
                                value={el.capacite}
                            />
                          </td>
                          <td className="col-md-2">
                            <Input
                                type="number"
                                className="form-control"
                                placeholder="Nombre Etudiant"
                                onChange={e => addNombreEtudiant(e, index)}
                                value={el.nombreEtudiant}
                            />
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                      >
                        <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Groupe")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                      >
                        <button
                            onClick={save}
                            type="submit"
                            className="btn btn-primary "
                        >
                          CONFIRMER
                        </button>
                      </div>
                    </Col>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {" "}
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    {" "}
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewGroupCour
ViewGroupCour.propTypes = {
  history: PropTypes.object,
}
