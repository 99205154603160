import React, { useState, useEffect, useMemo } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  CardTitle,
  Container,
  Spinner,
} from "reactstrap"

const EditSalle = props => {
  const [nom, setNom] = useState("")
  const [capacite, setCapacite] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("getById_salle", { id }).then(res => {
      setNom(res.data.Salle.name)
      setCapacite(res.data.Salle.capacite)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("salle/update", {
      id: id,
      name: nom,
      capacite:capacite
    })
      .then(res => {
        props.history.push("/Salle")
      })
      .catch(() => {
        toast.error("⛔ Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Form>
                      <Row>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Salle :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              placeholder="Salle"
                              type="text"
                              value={nom}
                              onChange={e => setNom(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              Capacité :
                            </Label>
                            <Input
                              lg="3"
                              className="form-control glyphicon glyphicon-star"
                              placeholder="Capacité"
                              type="text"
                              value={capacite}
                              onChange={e => setCapacite(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Salle")}
                          >
                            Annuler
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            CONFIRMER
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(EditSalle)
EditSalle.propTypes = {
  history: PropTypes.object,
}
