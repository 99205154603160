import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  CardTitle, Table,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"

const EtudiantGroupe = props => {
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [affiche, setAffiche] = useState(false)
  const [etudiantList, setEtudiantList] = useState([])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {
    const resC = await API.get("cycle/select").then(resC => {
      setCycle(resC.data.Cycle)
    })
  }, [])
  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    setSelectGroupe("")
    const res = await API.post("niveau/get_by_cycle_unique",{cycle_id:cycle_id}).then(res => {
      setNiveau(res.data.Niveau)
    })

  }
  const getSpec = async event => {
    var niveau_id = event.value
    setSelectSpecialite("")
    setSelectGroupe("")
    setSelectNiveau(event)
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }
  const getGroupe = async event => {
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    var specialite_id = event.value
    setSelectSpecialite(event)
    setSelectGroupe("")
    const res = await API.post("group/getbysp", {
      specialite_id: specialite_id,
      cycle_id: selectCycle.value,
      niveau_id: selectNiveau.value,
      annee_id:annee_id
    }).then(res => {
      setGroupe(res.data.groups)
    })
  }
  const display = () => {
    if(selectCycle == "" || selectNiveau =="" || selectSpecialite =="" || selectGroupe==""){
      setAffiche(false)
      toast.error("⛔ Tous les champs sont obligatoire !", {
        containerId: "A",
      })
    }else{
      setAffiche(true)
      API.post("etudiant/get_etudiant_group", {
        group_id:selectGroupe.value
      }).then(res => {
        setEtudiantList(res.data.Etudiant)
      })
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Cycle : </Label>
                <Select
                    options={cycle}
                    isSearchable={true}
                    value={selectCycle}
                    onChange={e => getNiveau(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Niveau : </Label>
                <Select
                    options={niveau}
                    isSearchable={true}
                    value={selectNiveau}
                    onChange={e => getSpec(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                <Select
                    options={specialite}
                    isSearchable={true}
                    value={selectSpecialite}
                    onChange={e => getGroupe(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe : </Label>
                <Select
                    options={groupe}
                    isSearchable={true}
                    value={selectGroupe}
                    onChange={e => setSelectGroupe(e)}
                />
              </div>
            </Col>
          </Row>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}

            <Col lg="6">
              <div className="text-center mt-4">
                <button
                    type="button"
                    className="btn btn-primary "
                    onClick={display}>
                  Afficher
                </button>
              </div>
            </Col>
          </div>
          {affiche ? (
              <div>
                <CardTitle style={{ color: "#556ee6" }} className="h3">
                  Liste des étudiants
                </CardTitle>
                <Table className="mt-3" striped bordered hover>
                  <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>CIN</th>
                    <th style={{ textAlign: "center" }}>Etudiant</th>
                  </tr>
                  </thead>
                  <tbody>
                  {etudiantList.map((el, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}> {el.cin} </td>
                        <td style={{ textAlign: "center" }}> {el.etudiant} </td>
                      </tr>
                  ))}
                  </tbody>
                </Table>
              </div>
          ):(null)}
        </Form>

      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />

    </React.Fragment>
  )
}

export default EtudiantGroupe
EtudiantGroupe.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
