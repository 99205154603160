import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Col,Row,Container, Card, CardBody, Spinner, CardTitle,} from "reactstrap"
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import {withTranslation} from "react-i18next"
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const EnseignantsGroupesDetail = props => {
    const [loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const NoDataIndication = () => (
        <h6 style={{textAlign: "center"}}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "group",
            text: "Groupe",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "matiere",
            text: "Matière",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
        {
            dataField: "semestre",
            text: "Semestre",
            sort: true,
            style: {
                textAlign: "center",
            },
            headerStyle: (colum, colIndex) => {
                return {textAlign: "center"}
            },
        },
    ]

    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        var url = window.location.href
        var array = url.split("=")
        var id = array[1]
        API.post("group/enseignant/matiere/get_by_enseignant_id", {enseignant_id: id, annee_id}).then(res => {
            setOrders(res.data.GroupEnseignantMatiere)
            setLoading(true)
        })
    }, [])
    return (
        <React.Fragment>
            <div>
                {loading ? (
                    <div className="page-content">
                        <Container fluid>
                            <Card>
                                <CardBody>
                                    <CardTitle style={{color: "#556ee6"}} className="h4">
                                        Détail affectation de groupes
                                    </CardTitle>
                                    <Row className="mt-4">
                                        <Col xs="12">
                                            <ToolkitProvider
                                                keyField="id"
                                                data={orders}
                                                columns={Columns()}
                                                search
                                                bootstrap4
                                            >
                                                {toolkitProps => (
                                                    <div>
                                                        <BootstrapTable
                                                            wrapperClasses="table-responsive"
                                                            noDataIndication={() => <NoDataIndication/>}
                                                            striped={false}
                                                            bordered={false}
                                                            classes={"table align-middle table-nowrap"}
                                                            headerWrapperClasses={"table-light"}
                                                            hover
                                                            {...toolkitProps.baseProps}
                                                        />
                                                    </div>
                                                )}
                                            </ToolkitProvider>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                ) : (
                    <div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Spinner type="grow" className="ms-6" color="primary"/>
                        </div>
                        <h4
                            style={{textAlign: "center", marginTop: "2%"}}
                            className="ms-6"
                        >
                            {" "}
                            En cours ...
                        </h4>
                    </div>
                )}
            </div>

            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withTranslation()(EnseignantsGroupesDetail)
EnseignantsGroupesDetail.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
}
