import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import { Col, Input, Row, Label, Form, Button } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Select from "react-select"
import { withTranslation } from "react-i18next"
const AddCycleNiveau = props => {
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("");
  const [niveaux, setNiveaux] = useState([])
  const [selectNiveaux, setSelectNiveaux] = useState("")


  //
  useEffect(async () => {
    const res = await API.get("select_niveau_semestre").then(res => {
      setNiveaux(res.data.Niveau)
    })
    const resCycle = await API.get("cycle/select").then(resCycle => {
      setCycle(resCycle.data.Cycle)
    })
  }, [])

  const save = async () => {
    const res = await API.post("cycle/niveau/add", {
      cycle_id: selectCycle.value,
      niveau_semestre_id: selectNiveaux,
    })
      .then(res => {
        props.setSection(3)
      })
      .catch(() => {
        toast.error("⛔  Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Cycle")}
                </Label>
                <Select
                    options={cycle}
                    isSearchable={true}
                    onChange={e => setSelectCycle(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Niveau")}
                </Label>
                <Select
                  isMulti
                  options={niveaux}
                  isSearchable={true}
                  onChange={e => setSelectNiveaux(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddCycleNiveau)
AddCycleNiveau.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}
