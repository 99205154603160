import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Row, Spinner, Container, Card, CardBody } from "reactstrap"
import API from "../../api"
import ESPS from "../../assets/images/logo__esps_imp.png"
import tunisie_rep from "../../assets/images/tn_rep.png"

const EmploiEnseignant = props => {
  const [loading, setLoading] = useState(false)
  const [quarts, setQuarts] = useState([])
  const [jours, setJours] = useState([])
  const [semestre, setSemestre] = useState([])
  const [enseignant, setEnseignant] = useState([])
  const [anneeUniversitaire, setAnneeUniversitaire] = useState("");

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id_semestre = array[1]
    var array1 = id_semestre.split("/")
    var id = array1[0]
    var semestre_id = array1[1]
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    const resE = await API.post("emploi/get_by_id", {
      enseignant_id: id,
      semestre_id: semestre_id,
      annee_id:annee_id
    }).then(resE => {
      setQuarts(resE.data.emploi[0].quarts)
      setJours(resE.data.emploi[0].jours)
      setSemestre(resE.data.emploi[0].semestre)
      setEnseignant(resE.data.emploi[0].enseignant)
      setAnneeUniversitaire(resE.data.emploi[0].annee_scolaire)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              {loading ? (
                <Row>
                  <div className="container">
                    <div className="row" style={{ paddingTop: "30px" }}>
                      <div
                        className="col"
                        style={{ fontSize: "12px", textAlign: "center" }}
                      >
                        République Tunisienne
                        <div
                          className="col"
                          style={{
                            fontSize: "12px",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {"Ecole de formation Professionnelle Privée de,"}
                        </div>
                        <div
                          className="col"
                          style={{ fontSize: "12px", textAlign: "center" }}
                        >
                          {" Technologie et de management de Sfax ESPS"}

                        </div>

                      </div>
                      <div
                        className="col"
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img  style={{  "margin-top": "-38px"  }} src={ESPS} />
                      </div>
                      <div
                        className="col"
                        style={{ position: "relative", textAlign: "center" }}
                      >
                        <img  style={{ width:"25%", "margin-top": "-33px" }} src={tunisie_rep} />
                      </div>
                      <div
                        className="col"
                        style={{ fontSize: "15px", textAlign: "center" }}
                      >
                        الجمهورية التونسية
                        <div
                          className="col"
                          style={{ fontSize: "15px", textAlign: "center" }}
                        >
                          {"مدرسة التكوين المهني الخاص للتكنولوجيا وإدارة الاعمال بصفاقس"}
                        </div>

                      </div>
                    </div>
                    <hr
                      style={{
                        width: "100%",
                        marginTop: "0.1%",
                        color: "black",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        justifyContent: "end",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>
                        Année Universitaire:{" "}
                      </span>
                      <span style={{ fontWeight: "bold" }}>{anneeUniversitaire}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        justifyContent: "end",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>Semestre:</span>
                      <span style={{ fontWeight: "bold" }}>{semestre}</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "15px",
                        justifyContent: "center",
                      }}
                    >
                      <span style={{ paddingRight: "4px" }}>Enseignant:</span>
                      <span style={{ fontWeight: "bold" }}>{enseignant}</span>
                    </div>
                  </div>
                  <div>
                    <div className="table-responsive mt-4">
                      <table className="table table-bordered text-center">
                        <thead className="table table-light">
                          <tr>
                            <th></th>
                            {quarts.map(el => (
                              <th
                                key={el.id_quart}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {el.label_quart}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                        {jours.map((elem, index) => (
                            <tr key={elem.id_jour}>
                              <td style={{ fontWeight: "bold" }}>
                                {elem.label_jour}
                              </td>
                              {elem.seances.map((element, indice) =>
                                  element.count !=0 ? (
                                      <td style={{ padding: "0px" }} className="td-emploi">
                                        {element.options.map((elle, ind) => (

                                            <div key={indice}
                                                 id={"hover_td" + index + indice + ind}
                                                 style={{
                                                   padding: "2px 2px 2px 2px",
                                                   cursor: "pointer",
                                                   borderBottom:
                                                       ind == 0 &&
                                                       element.options.length > 1
                                                           ? "1px solid #eff2f7"
                                                           : null,
                                                 }}
                                            >
                                              <p style={{textAlign: "center"}}>{elle.label_matiere}</p>
                                              <p style={{textAlign: "center"}}>{elle.label_group}</p>
                                              <p style={{textAlign: "center"}}>{elle.label_etudiant}</p>
                                              <p style={{textAlign: "center"}}>{elle.label_salle}</p>
                                              <div className="div-icon"><span className="icon-cour"> C</span></div>
                                            </div>
                                        ))}
                                      </td>

                                  ) : (
                                      <td className="td-emploi"></td>
                                  )
                              )}
                            </tr>
                        ))}
                        </tbody>
                      </table>
                      <div
                        className="mt-4"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() =>
                            props.history.push("/SchedulesEnseignants")
                          }
                        >
                          Annuler
                        </Button>
                      </div>
                    </div>
                  </div>
                </Row>
              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmploiEnseignant
EmploiEnseignant.propTypes = {
  history: PropTypes.object,
}
