import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import API from "../../api"
import {Button,Card,CardBody,CardTitle,Col,Container, Form,Label,Row,Spinner} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from "react-select";
const PaiementEnseignant = props => {
  const [loading, setLoading] = useState(true)
  const [id, setId] = useState("")
  const [typePiement, setTypePiement] = useState([]);
  const [selectTypePaiement, setSelectTypePaiement] = useState("");
  const [montant, setMontant] = useState("");
  const [numeroCheque, setNumeroCheque] = useState("");
  const [responsableCheque, setResponsableCheque] = useState("");
  const [dateCheque, setDateCheque] = useState("");
  const [nameBnque, setNameBnque] = useState("");
  const [etat, setEtat] = useState("none");
  const [matiere, setMatiere] = useState([]);
  const [selectMatiere, setSelectMatiere] = useState("");
  const [selectSemestre, setSelectSemestre] = useState("");
  const [cycle, setCycle] = useState([])
  const [selectCycle, setSelectCycle] = useState("")
  const [niveau, setNiveau] = useState([])
  const [selectNiveau, setSelectNiveau] = useState("")
  const [specialite, setSpecialite] = useState([])
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
    { value: 5, label: "Formation" },
  ])
  const [nbrHeure, setNbrHeure] = useState("");
  const [prixHeure, setPrixHeure] = useState("");
  const [montantTotal, setMontantTotal] = useState("");
  const [montantReste, setMontantReste] = useState("");
  const [montantPayee, setMontantPayee] = useState("");
  const [disbutReg, setDisbutReg] = useState(true)
  const [group, setGroup] = useState([]);
  const [selectGroup, setSelectGroup] = useState("");
  useEffect(async () => {
    setLoading(true)
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    setId(id)
    API.get("type_payment/selectlist").then(resC => {
      setTypePiement(resC.data.type_payment)
    })
  }, [])
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const getType = async event => {
    setSelectTypePaiement(event)
    if(event.value == 2){
      setEtat("none")
    }else if(event.value == 3){
      setEtat("")
    }
  }
  const getCycle = async event => {
    var semestre_id = event.value
    setSelectSemestre(event)
    setSelectCycle("")
    setSelectNiveau("")
    setSelectSpecialite("")
    const resC = await API.post("cycle/getByNiveauxId",{semestre_id:semestre_id}).then(resC => {
      setCycle(resC.data.Cycle)
    })
  }
  const getNiveau = async event => {
    var cycle_id = event.value
    setSelectCycle(event)
    setSelectNiveau("")
    setSelectSpecialite("")
    const res = await API.post("niveau/select_by_semestre",{semestre_id:selectSemestre.value}).then(res => {
      setNiveau(res.data.Niveau)
    })
  }
  const getSpec = async event => {
    var niveau_id = event.value
    setSelectNiveau(event)
    setSelectSpecialite("")
    const res = await API.post("specialite/get_by_niveau", {
      niveau_id: niveau_id,
      cycle_id: selectCycle.value,
    }).then(res => {
      setSpecialite(res.data.specialite)
    })
  }
  const getNbHeure = async event => {
    var matiere_id = event.value
    setSelectMatiere(event)
    API.post("ligne_payment/get_prix_heure",{
      matiere_id:matiere_id,
      specialite_id:selectSpecialite.value,
      enseignant_id:id
    }).then(resC => {
      if(resC.data.prix_heure.rest_paye == 0){
        setDisbutReg(false)
      }else{
        setDisbutReg(true)
      }
      setNbrHeure(resC.data.prix_heure.nb_heure)
      setPrixHeure(resC.data.prix_heure.prix_heure)
      setMontantTotal(resC.data.prix_heure.prix_total)
      setMontantPayee(resC.data.prix_heure.prix_paye)
      setMontantReste(resC.data.prix_heure.rest_paye)
    })
    API.post("enseignant/get_all_group_ensei",{
      matiere_id:matiere_id,
      semestre_id:selectSemestre.value,
      enseignant_id:id,
      annee_id
    }).then(resGroup => {
        setGroup(resGroup.data.groups)
    })
  }
  const getMatiere = async event => {
    var specialite_id = event.value
    setSelectSpecialite(event)
    API.post("matiere/get_by_niv_sem_spec_anne",{
      cycle_id:selectCycle.value,
      niveau_id:selectNiveau.value,
      semestre_id:selectSemestre.value,
      specialite_id:specialite_id,
      annee_id:annee_id
    }).then(resC => {
      setMatiere(resC.data.groups)
    })
  }
  const save = async () => {
    if (dateCheque != "") {
      let dateComm = dateCheque
      let month = "" + (dateComm.getMonth() + 1)
      let day = "" + dateComm.getDate()
      let year = dateComm.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }

    if (selectTypePaiement == "") {
      toast.error("⛔ Veuillez choisir un Type de paiement", {
        containerId: "A",
      })
    } else if (montant == "") {
      toast.error("⛔ Veuillez saisir un montant ", {
        containerId: "A",
      })
    }else if (selectMatiere == "") {
      toast.error("⛔ Veuillez choisir une matière ", {
        containerId: "A",
      })
    }else{
      if(selectTypePaiement.value == 2){
        const res = await API.post("enseignant/store", {
          enseignant_id:id,
          matiere_id:selectMatiere.value,
          type_payment_id:selectTypePaiement.value,
          user_id:user_id,
          specialite_id:selectSpecialite.value,
          prix:montant,
          prix_heure:prixHeure,
          group_id:selectGroup.value,
          semestre_id:selectSemestre.value,
          annee_id
        }).then(res => {
          if(res.data.errNum ==400){
            toast.error("⛔ Vous avez dépassée le montant total ", {
              containerId: "A",
            })
          }else{
            props.history.push("/Enseignant")
          }
        })
      }
      else if(selectTypePaiement.value == 3){
        if (convertDate == "") {
          toast.error("⛔ Veuillez choisir une date de chèque ", {
            containerId: "A",
          })
        }else if (selectMatiere == "") {
          toast.error("⛔ Veuillez choisir une matière ", {
            containerId: "A",
          })
        }else if(numeroCheque == ""){
          toast.error("⛔ Veuillez saisir un numéro de chèque ", {
            containerId: "A",
          })
        }else if(responsableCheque == ""){
          toast.error("⛔ Veuillez saisir un responsable de chèque ", {
            containerId: "A",
          })
        }else if( nameBnque == ""){
          toast.error("⛔ Veuillez saisir le nom du banque ", {
            containerId: "A",
          })
        }
        else{
          const res = await API.post("enseignant/store", {
            enseignant_id:id,
            matiere_id:selectMatiere.value,
            user_id:user_id,
            type_payment_id:selectTypePaiement.value,
            specialite_id:selectSpecialite.value,
            numero_cheque:numeroCheque,
            responsable_cheque:responsableCheque,
            date_cheque:convertDate,
            name_banque:nameBnque,
            prix:montant,
            prix_heure:prixHeure,
            group_id:selectGroup.value,
            semestre_id:selectSemestre.value,
            annee_id
          }).then(res => {
            if(res.data.errNum ==400){
              toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
                containerId: "A",
              })
            }else if(res.data.errNum ==404){
              toast.error("⛔ Vous avez dépassée le montant total de spécialité ", {
                containerId: "A",
              })
            }else{
              props.history.push("/Enseignant")
            }
          })
        }
      }
    }
  }
  const handleKeyUp = (event) => {
    setPrixHeure(event.target.value)
    setMontantTotal(event.target.value * nbrHeure)
    setMontantReste((event.target.value * nbrHeure)-montantPayee)
    if((event.target.value * nbrHeure)-montantPayee == 0 || (event.target.value * nbrHeure)-montantPayee > 0){
      setDisbutReg(true)
    }else{
      setDisbutReg(false)
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                    <Row>
                      <AvForm>
                        <Form>
                          <CardTitle style={{ color: "#556ee6" }} className="h4 mb-4">
                            Paiement Enseignant :
                          </CardTitle>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Semestre : </Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    value={selectSemestre}
                                    onChange={e => getCycle(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle : </Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    value={selectCycle}
                                    onChange={e => getNiveau(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau : </Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    value={selectNiveau}
                                    onChange={e => getSpec(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité :</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    value={selectSpecialite}
                                    onChange={e => getMatiere(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Matière : </Label>
                                <Select
                                    options={matiere}
                                    isSearchable={true}
                                    value={selectMatiere}
                                    onChange={e => getNbHeure(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Groupe : </Label>
                                <Select
                                    options={group}
                                    isSearchable={true}
                                    value={selectGroup}
                                    onChange={e => setSelectGroup(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nombre Heure : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={nbrHeure}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Prix Heure : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    value={prixHeure}
                                    onKeyUp={handleKeyUp}
                                    // onChange={e => setPrixHeure(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant total : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantTotal}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Total payée : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantPayee}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                            <Col lg="3">
                              <div className="mb-3">
                                <Label style={{ color: "red" }} for="basicpill-firstname-input1">Montant Reste à payée: </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="text"
                                    className="form-control"
                                    value={montantReste}
                                    disabled={true}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Type paiement : </Label>
                                <Select
                                    options={typePiement}
                                    isSearchable={true}
                                    value={selectTypePaiement}
                                    onChange={e => getType(e)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Montant : </Label>
                                <AvField
                                    name="montant"
                                    placeholder="0.000"
                                    type="number"
                                    className="form-control"
                                    onChange={e => setMontant(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display:etat}} >
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                  Date de Chèque :
                                </Label>
                                <Col md={12} className="pr-0">
                                  <DatePicker
                                      className="form-control ddate"
                                      selected={dateCheque}
                                      onChange={e => setDateCheque(e)}
                                      dateFormat="dd/MM/yyyy"
                                  />
                                </Col>
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Numéro de chèque : </Label>
                                <AvField
                                    name="numero_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNumeroCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row style={{display:etat}}>
                            <Col  lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Responsable de chèque : </Label>
                                <AvField
                                    name="responsable_cheque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setResponsableCheque(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Nom du banque : </Label>
                                <AvField
                                    name="nom_banque"
                                    type="text"
                                    className="form-control"
                                    onChange={e => setNameBnque(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          {" "}
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>props.history.push("/Enseignant")}
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  id="Confirmer"
                                  name="Confirmer"
                                  type="button"
                                  color="primary"
                                  className="btn btn-primary mb-2 me-2"
                                  disabled={!disbutReg}
                                  onClick={save}
                              >
                                CONFIRMER
                              </button>
                            </div>
                          </Col>
                        </div>
                      </AvForm>
                    </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaiementEnseignant
PaiementEnseignant.propTypes = {
  history: PropTypes.object,
  back:PropTypes.object
}
