import React, { useEffect, useState } from "react"
import PropTypes, { array } from "prop-types"
import API from "../../api"
import APIE from "../../apiens"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Button,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Form,
  Label,
} from "reactstrap"
import { withRouter } from "react-router"
import Select from "react-select"
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import Switch from "react-switch"
import FileDownload from "js-file-download";
//
const DataTableEnseignantAffectation = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [id, setId] = useState("")
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Next",
    prePageText: "Back",
    sizePerPage: 30,
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "group",
      text: "Groupe",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: "Enseignant",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: "Matière",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: "Semestre",
      sort: true,
      style: {
        textAlign: "center",
      },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "suppression",
      isDummyField: true,
      text: "Suppression",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      // eslint-disable-next-line react/display-name
      formatter: (colIndex, row) => (
          <div>
            <div style={{display: "flex", justifyContent: "center"}}>
              <i
                  style={{color: "#ED6464", cursor: "pointer"}}
                  onClick={() => toggle(row.id)}
                  className="fas fa-trash-alt"
              ></i>
            </div>

          </div>
      ),
    },
  ]
  const toggle = id => {
    setModal(!modal)
    setId(id)
  }


  const { SearchBar } = Search

  useEffect(async () => {
      API.post("group/enseignant/matiere/list",{annee_id}).then(res => {
        setOrders(res.data.GroupEnseignantMatiere)
        setLoading(true)
      })
  }, [])
  const toggleDelete = async () => {
    API.post("group/enseignant/matiere/delete", {
      id: id,
    }).then(res => {
      setModal(false)
      API.post("group/enseignant/matiere/list",{annee_id}).then(res => {
        setOrders(res.data.GroupEnseignantMatiere)
        setLoading(true)
      })
    })
  }

  return (
      <React.Fragment>
        <div>
          {loading ? (
              <Row className="mt-4">
                <Col xs="12">
                  <ToolkitProvider
                      keyField="id"
                      data={orders}
                      columns={Columns()}
                      search
                      bootstrap4
                  >
                    {toolkitProps => (
                        <div>
                          <div>
                            <div className="mb-3 row" style={{ display: "flex" }}>
                              <div className="col-md-6 mb-3 row">
                                <div className="col-md-8 search-box-etd">
                                  <div
                                      className="search-box me-2 mb-2 d-inline-block"
                                      style={{ width: "100%" }}
                                  >
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                          <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              pagination={paginationFactory(pageOptions)}
                              {...toolkitProps.baseProps}
                          />
                        </div>
                    )}
                  </ToolkitProvider>
                </Col>
              </Row>
          ) : (
              <div>
                <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                >
                  <Spinner type="grow" className="ms-6" color="primary" />
                </div>
                <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                >
                  {" "}
                  En cours ...
                </h4>
              </div>
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader
              style={{width: "100% !important", textAlign: "center !important"}}
              toggle={toggle}
              tag="h4"
          >
            {" Suppression affectation enseignant de groupes"}{" "}
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{display: "flex", justifyContent: "center"}}>
                <p>Voulez-vous supprimer cette affectation ?</p>
              </div>
              <div
                  className="hvr-push"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button
                      color="info"
                      className="btn-rounded "
                      onClick={toggleDelete}
                  >
                    <i
                        style={{color: "white"}}
                        className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggle}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{color: "white"}}
                        className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(DataTableEnseignantAffectation)
DataTableEnseignantAffectation.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
}
