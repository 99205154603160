import React, { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../api"
import "react-datepicker/dist/react-datepicker.css"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

const AddEnseignant = props => {
  const [disbutReg, setDisbutReg] = useState(true)
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [gender, setGender] = useState(1)

  useEffect(async () => {

  }, [])


  const save = async () => {
    setDisbutReg(false)
    if (nom == "" || prenom == "" || numero == "" ) {
      setDisbutReg(true)
      toast.error("⛔ Nom , Prenom , CIN obligatoires", {
        containerId: "A",
      })
    } else {
      const res = await API.post("enseignant/add", {
        firstName: nom,
        lastName: prenom,
        gender: gender,
        code: numero,
        email: email,
        telephone: tel,
        active:0
      }).then(res => {
        if(res.data.errNum == 500){
          setDisbutReg(true)
          toast.error("⛔ Ce cin exist déjà pour un autre utilisateur", {
            containerId: "A",
          })
        }else if(res.data.errNum ==501){
          setDisbutReg(true)
          toast.error("⛔ Ce mail exist déjà pour un autre utilisateur", {
            containerId: "A",
          })
        }else  if (res.data.status === 200) {
          props.setSection(1)
        }
      })
    }
  }
  return (
      <React.Fragment>
        <Row>
          <AvForm>
            <Form>
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Nom :</Label>
                    <AvField
                        name="nom"
                        placeholder="Entrer nom"
                        type="text"
                        errorMessage="* nom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setNom(e.target.value)}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Prénom :</Label>
                    <AvField
                        name="prenom"
                        placeholder="Entrer prénom"
                        type="text"
                        errorMessage="* prenom obligatoire"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={e => setPrenom(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="mb-3">
                    <Label className="d-block mb-3">Sexe :</Label>
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline1"
                          name="customRadioInline1"
                          className="form-check-input"
                          defaultChecked
                          onClick={() => setGender(1)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline1"
                      >
                        Masculin
                      </Label>
                    </div>
                    &nbsp;
                    <div className="form-check form-check-inline">
                      <Input
                          type="radio"
                          id="customRadioInline2"
                          name="customRadioInline1"
                          className="form-check-input"
                          onClick={() => setGender(2)}
                      />
                      <Label
                          className="form-check-label"
                          htmlFor="customRadioInline2"
                      >
                        Feminin
                      </Label>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Identifiant</Label>
                    <AvField
                        name="numero"
                        placeholder="Entrer identifiant"
                        type="number"
                        errorMessage="Min 8 caractère"
                        className="form-control"
                        validate={{ required: { value: true }, min: { value: 8 } }}
                        onChange={e => setNumero(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
               <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <AvField
                        name="email"
                        label="E-Mail"
                        placeholder="Entrer un email valide"
                        type="email"
                        errorMessage="Email invalide"
                        onChange={e => setEmail(e.target.value)}
                        validate={{
                          // required: { value: true },
                          email: { value: true },
                        }}
                    />
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="basicpill-firstname-input1">Téléphone : </Label>
                    <AvField
                        name="tel"
                        placeholder="Entrer téléphone"
                        type="text"
                        errorMessage="* Téléphone obligatoire"
                        className="form-control"
                        validate={{  min: { value: 8 } }}
                        onChange={e => setTel(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>




            </Form>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {" "}
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="text-center mt-4"
                >
                  <Button
                      type="button"
                      color="warning"
                      className="btn btn-warning  mb-2 me-2"
                      onClick={props.back}
                  >
                    Annuler
                  </Button>
                </div>
              </Col>
              <Col lg="6">
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    className="text-center mt-4"
                >
                  <button
                      disabled={!disbutReg}
                      type="submit"
                      className="btn btn-primary "
                      onClick={save}
                  >
                    CONFIRMER
                  </button>
                </div>
              </Col>
            </div>
          </AvForm>
        </Row>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
      </React.Fragment>
  )
}

export default withRouter(AddEnseignant)
AddEnseignant.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
}
